import { createSelector } from 'reselect';
import {
  clone,
  contains,
  propOr,
} from 'ramda';
import { v4 } from 'uuid';
import { convertLineItemsToCartItems } from '../util/menuItemHelpers';
import { types as orderTypes } from './order';
import ooeConstants from '../constants';
import makeActionCreator from '../util/makeActionCreator';

export const types = {
  ADD_TO_CART: '[Cart] add',
  UPDATE_QUANTITY: '[Cart] Update quantity',
  DELETE_ITEM: '[Cart] Delete item',
  ADD_MODIFIER: '[Cart] Add modifier',
  UPDATE_MODIFIER_QUANTITY: '[Cart] Update modifier quantity',
  UPDATE_SPECIAL_INSTRUCTIONS: '[Cart] Update special instructions',
  UPDATE_SIDE_ITEM: '[Cart] Update side item',
  UPDATE_DESSERT_ITEM: '[Cart] Update dessert item',
  REMOVE_NON_EXISTENT_ITEMS: '[Cart] remove items that are no longer in the menu',
  MAKE_PROMO_FREE: '[Cart] Promo free item',
  REMOVE_PROMO_FREE: '[Cart] Remove promo free from item',
};

export const actions = makeActionCreator({
  addToCart: (item) => ({
    type: types.ADD_TO_CART,
    item,
    tag: item.tag,
  }),
  updateQuantity: (id, quantity) => ({
    type: types.UPDATE_QUANTITY, quantity, id,
  }),
  deleteItem: (id) => ({ type: types.DELETE_ITEM, id }),
  addModifier: (id, modifier, { comboTag } = {}) => ({
    type: types.ADD_MODIFIER,
    modifier,
    comboTag,
    modifierTag: modifier.tag,
    id,
  }),
  updateModifierQuantity: (id, modifier, quantity, { comboTag }) => ({
    type: types.UPDATE_MODIFIER_QUANTITY,
    modifier,
    quantity,
    comboTag,
    modifierTag: modifier.tag,
    id,
  }),
  updateSpecialInstructions: (id, specialInstructions) => ({
    type: types.UPDATE_SPECIAL_INSTRUCTIONS,
    specialInstructions,
    id,
  }),
  updateSideItem: (id, side) => ({
    type: types.UPDATE_SIDE_ITEM, side, id,
  }),
  updateDessertItem: (id, dessert) => ({
    type: types.UPDATE_DESSERT_ITEM, dessert, id,
  }),
  removeNonExistentItems: (tags) => ({ type: types.REMOVE_NON_EXISTENT_ITEMS, tags }),
  makePromoFree: (id) => ({ type: types.MAKE_PROMO_FREE, id }),
  removePromoFree: (id) => ({ type: types.REMOVE_PROMO_FREE, id }),
});

export const initialState = [];

function getIndex(newState, id) {
  return newState.findIndex((item) => item.id === id);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TO_CART: {
      const { tag, sideItems, dessertItems } = action.item;
      const selectedSideTag = (propOr(undefined, '0', sideItems) || {}).tag;
      const selectedDessertTag = (propOr(undefined, '0', dessertItems) || {}).tag;
      return [
        ...state,
        {
          tag,
          selectedSideTag,
          selectedDessertTag,
          quantity: 1,
          modifiers: {},
          specialInstructions: '',
          id: v4(),
        },
      ];
    }

    case types.UPDATE_QUANTITY: {
      const { id } = action;
      const quantity = +action.quantity;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      if (quantity === 0) {
        newState.splice(idx, 1);
        return newState;
      }
      newState[idx] = {
        ...newState[idx],
        quantity,
      };
      return newState;
    }

    case types.UPDATE_SIDE_ITEM: {
      const { side, id } = action;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        selectedSideTag: side.tag,
      };
      return newState;
    }

    case types.UPDATE_DESSERT_ITEM: {
      const { dessert, id } = action;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        selectedDessertTag: dessert.tag,
      };
      return newState;
    }

    case types.UPDATE_MODIFIER_QUANTITY:
    case types.ADD_MODIFIER: {
      const {
        modifier,
        comboTag,
        quantity,
        id,
      } = action;
      let modifiers;
      let setQuantity = +quantity;
      const { tag } = modifier;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      const existingItem = newState[idx];
      const toggleable = contains(tag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
      // multigrain bun can only have quantity of 1 or 0
      if (toggleable) {
        setQuantity = modifier.quantity === 0 ? 1 : 0;
      }

      // prevent adding modifiers to items that don't exist
      if (!existingItem) {
        return newState;
      }
      const existingModifier = existingItem.modifiers[tag] || { quantity: 0 };

      if (!setQuantity && setQuantity !== 0) {
        setQuantity = +existingModifier.quantity + 1;
      }

      if (setQuantity === 0) {
        modifiers = { ...existingItem.modifiers };
        delete modifiers[tag];
      } else {
        modifiers = {
          ...existingItem.modifiers,
          [tag]: {
            tag,
            quantity: setQuantity,
            comboTag,
          },
        };
      }
      newState[idx] = { ...existingItem, modifiers };
      return newState;
    }

    case types.UPDATE_SPECIAL_INSTRUCTIONS: {
      const { specialInstructions, id } = action;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        specialInstructions,
      };
      return newState;
    }

    case orderTypes.INITIATE_EDIT_ORDER: {
      const { order: { lineItems } } = action;
      return convertLineItemsToCartItems(lineItems, false, true);
    }

    case types.MAKE_PROMO_FREE: {
      const { id } = action;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        promoFree: true,
      };
      return newState;
    }
    case types.REMOVE_PROMO_FREE: {
      const { id } = action;
      const newState = clone(state);
      const idx = getIndex(newState, id);
      newState[idx] = {
        ...newState[idx],
        promoFree: false,
      };
      return newState;
    }

    case types.DELETE_ITEM: {
      const { id } = action;
      return state.filter((item) => item.id !== id);
    }

    case types.REMOVE_NON_EXISTENT_ITEMS: {
      const { tags } = action;
      return state.filter((item) => !contains(item.tag, tags));
    }

    case orderTypes.EXIT_EDIT_ORDER:
    case orderTypes.SUBMIT_ORDER_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export const selectCart = (state) => state.cart;

export const selectCartItemsLength = createSelector(
  selectCart,
  (cart) => cart.reduce((acc, val) => acc + val.quantity, 0),
);

export const selectPromoFreeActive = createSelector(
  selectCart,
  (cart) => cart.some(c => c.promoFree === true),
);

export const selectPromoFreeItemCount = createSelector(
  selectCart,
  (cart) => {
    let itemCount = 0;
    cart.forEach((item) => {
      itemCount += item?.promoFree ? item.quantity : 0;
    });
    return itemCount;
  },
);

export const selectShowMaxPromoFreeItemCountWarning = createSelector(
  selectPromoFreeItemCount,
  (count) => count > ooeConstants.MAX_PROMO_FREE_ITEM_QTY,
);

export const selectMaxPromoFreeQtySubmitWarning = createSelector(
  selectShowMaxPromoFreeItemCountWarning,
  (qtyInvalid) => {
    if (qtyInvalid) {
      return {
        type: 'error',
        message: `Total quantity of promo free items must be less than ${ooeConstants.MAX_PROMO_FREE_ITEM_QTY}`,
      };
    }
    return {};
  },
);
