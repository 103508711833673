import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Disclaimer from '../Disclaimer/Disclaimer';

export const Textarea = props => {
  const {
    input,
    maxLength,
    defaultValue,
    placeholder,
    onBlur,
    className,
  } = props;

  const value = defaultValue || input.value || '';
  const blur = input.onBlur || onBlur;

  const [charsRemaining, setCharsRemaining] = useState(maxLength - value.length);

  useEffect(() => {
    setCharsRemaining(maxLength - value.length);
  }, [maxLength, value]);

  const updateCharCount = (event) => {
    const charCount = event.target.value.length;
    const charsRem = maxLength - charCount;
    if (charsRem >= 0) {
      setCharsRemaining(charsRem);
    }
  };

  return (
    <StyledTextarea>
      <textarea
        className={className}
        placeholder={placeholder}
        onBlur={blur}
        maxLength={maxLength.toString()}
        defaultValue={value}
        onChange={updateCharCount}
      />
      <Disclaimer className="instructions">
        {`${charsRemaining} Characters Remaining`}
      </Disclaimer>
    </StyledTextarea>
  );
};

const StyledTextarea = styled.div`
  & textarea {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.outline};
    border-radius: 0px;
    appearance: none;
    height: 85px;
    padding: 7px 10px;
    box-sizing: border-box;
    margin: 10px;
    width: calc(100% - 20px);

    &:focus {
      outline: none;
    }
    
    &::placeholder {
      color: ${(props) => props.theme.outline};
    }
  }
  
  & .instructions {
    margin-top: -5px;
    width: calc(100% - 22px);
    text-align: right !important; 
  }

`;

Textarea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  input: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
};

Textarea.defaultProps = {
  className: '',
  maxLength: 1000,
  defaultValue: '',
  onBlur: () => {},
  input: '',
};

export default memo(Textarea);
