import {
  curry,
  prop,
  filter,
  pluck,
} from 'ramda';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  map,
  switchMap,
  take,
  mapTo,
} from 'rxjs/operators';
import { types as menuTypes } from '../reducers/menu';
import { types as orderTypes } from '../reducers/order';
import {
  actions as cartActions,
  selectCart,
} from '../reducers/cart';
import { types as userTypes } from '../reducers/user';
import { selectIndexedSellableItems } from '../reducers';

const isCartItemNotInMenu = curry((indexedMenu, { tag }) => prop(tag, indexedMenu) === undefined);

export const RemoveNonExistentCartItems = (action$, store) => action$
  .pipe(
    ofType(
      orderTypes.CHANGE_DESTINATION,
      userTypes.UPDATE_USER_LOCATION,
    ),
    switchMap((action) => {
      if (action.type === userTypes.UPDATE_USER_LOCATION) {
        return action$
          .pipe(
            ofType(menuTypes.MENU_SUCCESS),
            take(1),
            switchMap(() => action$.pipe(
              ofType(menuTypes.MENU_SUCCESS),
            )),
            mapTo(action),
          );
      }
      return of(action);
    }),
    map(() => {
      const state = store.value;
      const cart = selectCart(state);
      const menu = selectIndexedSellableItems(state);
      return filter(isCartItemNotInMenu(menu), cart);
    }),
    map(pluck('tag')),
    map(cartActions.removeNonExistentItems),
  );

export default [
  RemoveNonExistentCartItems,
];
