import { Box, Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';
import ValidationMessage from './ValidationMessage';
import Disclaimer from '../Disclaimer/Disclaimer';

const DestinationFormHeader = (props) => {
  const {
    deliveryRange,
    clearUserAddressFields,
    deliveryAddress,
  } = props;

  const {
    streetNumber,
    route,
    subpremise,
    locality,
    postalCode,
    companyName,
    administrativeAreaLevelOne,
  } = deliveryAddress;
  const clearButtonIsShown = streetNumber !== ''
    || route !== ''
    || subpremise !== ''
    || locality !== ''
    || administrativeAreaLevelOne !== ''
    || postalCode !== ''
    || companyName !== '';

  return (
    <StyledDestinationFormHeader width={[1]}>
      <Flex justifyContent="space-between" alignItems="bottom">
        <div className="text-box">
          <ValidationMessage {...props} />
          <Disclaimer style={{ paddingLeft: '22px' }}>{`Delivery Range: ${deliveryRange}mi`}</Disclaimer>
        </div>
        {clearButtonIsShown && (
          <Close onClick={clearUserAddressFields} className="clear">
            <Icon>close</Icon>
          </Close>
        )}
      </Flex>
    </StyledDestinationFormHeader>
  );
};

export const Close = styled('div')`
  margin-right: 10px;
  & svg {
    margin: 0;
    width: 25px;
    height: 25px;
    fill: ${(props) => props.theme.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

const StyledDestinationFormHeader = styled(Box)`
  & .text-box{
    @media(max-width: ${props => props.theme.phone}) {
      max-width: calc(100% - 25px);
    }
    & svg {
      min-width: 17px;
    }
  }
  & .message {
    line-height: 1;
    @media(max-width: ${props => props.theme.phone}) {
     padding-bottom: 5px;
    }
  }
`;

DestinationFormHeader.propTypes = {
  deliveryRange: PropTypes.number,
  clearUserAddressFields: PropTypes.func,
  deliveryAddress: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
};

DestinationFormHeader.defaultProps = {
  deliveryRange: 0,
  clearUserAddressFields: () => {},
  deliveryAddress: {},
};

export default DestinationFormHeader;
