import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Flex } from '@cfacorp/cowponents';
import { formatModifier, formatModifierPrice } from '../../util/format';

function OrderSummaryModifiers({
  item, side, dessert, showPrices, promoFree,
}) {
  const { modifiers, quantity } = item;

  const renderComboItem = (comboItem) => (
    comboItem && comboItem.name && (
      <Flex className="modifier-group selectedComboItem" key={`${comboItem.tag || comboItem.itemTag}-order-summary`}>
        <Box width={6.5 / 12} textAlign="left" ml="4.1%">{comboItem.name}</Box>
        <Box width={3.5 / 12} textAlign="center">{comboItem.displayQuantity}</Box>
        {showPrices && <Box width={1.5 / 12} textAlign="right">{promoFree ? 'Promo Free' : formatModifierPrice(comboItem.price)}</Box>}
      </Flex>
    )
  );

  return (
    <StyledOrderSummaryModifiers>
      { modifiers && modifiers.map((mod) => {
        //this is added because api gives us discount with modifiers which have the same tag and it duplicates modifier keys
        let modTag = mod?.tag;
        if (mod.tag && typeof (mod.tag) === 'string') {
          const checkTag = modTag.slice(0, 5);
          if (checkTag === 'Local') {
            modTag = mod.itemNumber;
          }
        }
        return (
          //mod.itemNumber is added in case when we have tax items which modifiers don't have tags
          <Flex className="modifier-group" key={`${modTag || mod.itemNumber}-order-summary`}>
            <Box width={6.5 / 12} textAlign="left" ml="4.1%">{formatModifier(mod, quantity)}</Box>
            <Box width={3.5 / 12} textAlign="center">{mod.displayQuantity || mod.quantity}</Box>
            {showPrices && <Box width={1.5 / 12} textAlign="right">{promoFree ? 'Promo Free' : formatModifierPrice(mod.price)}</Box>}
          </Flex>
        );
      })}
      {renderComboItem(side)}
      {renderComboItem(dessert)}
      <Box ml="4.1%" color="primary" className="specials">{item.specialInstructions || ''}</Box>
    </StyledOrderSummaryModifiers>
  );
}

const StyledOrderSummaryModifiers = styled.div`
  font: ${(props) => props.theme.regularTextFont};
  & .modifier-group, .selectedComboItem, .specials {
     line-height: 27px;
  }
`;

OrderSummaryModifiers.propTypes = {
  item: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
  side: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
  dessert: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
  showPrices: PropTypes.bool,
  promoFree: PropTypes.bool,
};

OrderSummaryModifiers.defaultProps = {
  side: {},
  dessert: {},
  showPrices: true,
  item: {},
  promoFree: false,
};

export default OrderSummaryModifiers;
