export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      const queryVariable = decodeURIComponent(pair[1]);
      if (typeof queryVariable === 'string') {
        return queryVariable.trim();
      }
      return queryVariable;
    }
  }
  return undefined;
}

export default {
  getQueryVariable,
};
