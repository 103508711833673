/* eslint-disable react/forbid-prop-types */
import { memo, useEffect, useRef } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button, Section } from '@cfacorp/cowponents';

import {
  CartItem,
  EmptyCart,
  SlideModal,
  Totals,
} from '../components';
import { actions, selectPromoFreeActive, selectShowMaxPromoFreeItemCountWarning } from '../reducers/cart';
import { selectCartWithPrices, selectTaxAndTotal } from '../reducers';
import { selectFormattedSubTotalAmount, selectFormattedTaxAmount, selectOrderIsLoading } from '../reducers/order';
import { isAdminUser, isVcaUser } from '../reducers/user';
import usePrevious from '../util/customHooks';

const StyledCartSection = styled(Section)`
  padding-top: 15px !important;
  & .all-totals {
    padding-top: 15px;
  }
`;

const CheckoutButton = styled(Button)`
  min-width: 50%;
  margin: 40px auto;
  display: block;
`;

export const Cart = props => {
  const {
    updateQuantity,
    cartItemId,
    updateSpecialInstructions,
    addModifier,
    updateModifierQuantity,
    deleteItem,
    updateSideItem,
    updateDessertItem,
    addToCart,
    makePromoFree,
    removePromoFree,
    isAdmin,
    isVca,
    cartItems,
    subTotal,
    taxAndTotal,
    orderLoading,
    visible,
    promoFreeActive,
    showPromoFreeMaxQtyWarning,
    taxAmount,
  } = props;

  const itemRef = useRef(null);
  const prevCartItemId = usePrevious({ cartItemId });
  const ensureActiveItemVisible = () => {
    if (itemRef.current && itemRef.current.scrollIntoView) {
        itemRef.current.scrollIntoView({ block: 'center' });
    }
  };

  useEffect(() => { ensureActiveItemVisible(); }, []);
  useEffect(() => {
    if (prevCartItemId?.cartItemId !== cartItemId) ensureActiveItemVisible();
  }, [cartItemId]);

  const renderCartItem = (item) => {
    const { id } = item;
    const active = id === cartItemId;
    const itemProps = {
      item,
      items: item.items,
      addModifier,
      updateModifierQuantity,
      editMode: active,
      updateSpecialInstructions: (instr) => updateSpecialInstructions(id, instr),
      updateQuantity: (qty) => updateQuantity(id, qty),
      deleteItem: () => deleteItem(id),
      updateSideItem,
      updateDessertItem,
      addToCart,
      id,
      makePromoFree: () => makePromoFree(id),
      removePromoFree: () => removePromoFree(id),
      isAdmin,
      isVca,
      showPromoFreeMaxQtyWarning,
    };
    const renderProps = { key: id };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <div {...renderProps} data-testid="cart-item" ref={itemRef} key={id + active}><CartItem {...itemProps} /></div>;
  };

  return (
    <SlideModal
      title="Cart"
      visible={visible}
      className="cart"
      right={(
        <NavLink to={{
          state: {
            modal: false,
            cartItemId: null,
          },
        }}
        >
          <Button fontSize="15px" className="close-cart" variant="transparent" secondary data-cy="done">Close</Button>
        </NavLink>
      )}
    >
      {cartItems.length > 0
        ? (
          <StyledCartSection>
            {cartItems.map(renderCartItem)}
            <Totals
              subTotal={subTotal}
              taxAndTotal={taxAndTotal}
              isLoading={orderLoading}
              promoFreeActive={promoFreeActive}
              taxAmount={taxAmount}
            />
            <NavLink to={{ pathname: '/payment', state: { modal: false } }} style={{ textDecoration: 'none' }}>
              <CheckoutButton className="check-out-button" data-cy="check-out-button" variant="primary">Check Out</CheckoutButton>
            </NavLink>
          </StyledCartSection>
        )
        : <EmptyCart className="empty-cart" message="Cart Empty" />}
    </SlideModal>
  );
};

Cart.propTypes = {
  updateQuantity: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  addModifier: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  updateModifierQuantity: PropTypes.func.isRequired,
  updateSpecialInstructions: PropTypes.func.isRequired,
  updateSideItem: PropTypes.func.isRequired,
  updateDessertItem: PropTypes.func.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.any),
  subTotal: PropTypes.string,
  cartItemId: PropTypes.string,
  taxAndTotal: PropTypes.string,
  orderLoading: PropTypes.bool,
  visible: PropTypes.bool,
  makePromoFree: PropTypes.func,
  removePromoFree: PropTypes.func,
  isAdmin: PropTypes.bool,
  isVca: PropTypes.bool,
  promoFreeActive: PropTypes.bool,
  showPromoFreeMaxQtyWarning: PropTypes.bool,
  taxAmount: PropTypes.string,
};

Cart.defaultProps = {
  cartItems: [],
  subTotal: PropTypes.string,
  taxAndTotal: PropTypes.string,
  orderLoading: false,
  cartItemId: null,
  visible: false,
  makePromoFree: () => {},
  removePromoFree: () => {},
  isAdmin: false,
  isVca: false,
  promoFreeActive: false,
  showPromoFreeMaxQtyWarning: false,
  taxAmount: '',
};

function mapStateToProps(state) {
  return {
    cartItems: selectCartWithPrices(state),
    subTotal: selectFormattedSubTotalAmount(state),
    taxAndTotal: selectTaxAndTotal(state),
    orderLoading: selectOrderIsLoading(state),
    isAdmin: isAdminUser(state),
    isVca: isVcaUser(state),
    promoFreeActive: selectPromoFreeActive(state),
    showPromoFreeMaxQtyWarning: selectShowMaxPromoFreeItemCountWarning(state),
    taxAmount: selectFormattedTaxAmount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(Cart));
