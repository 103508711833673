import ooeConstants from '../constants';
import normalizePhone from '../util/normalizePhone';
import request from '../util/requestFactory';

const mapUnboundCustomer = (unboundCustomer) => {
  const {
    name: { givenName, familyName },
    phoneNumbers,
    email,
    uid,
    loyaltyCardNumber,
  } = unboundCustomer;

  let phone = '';
  if (phoneNumbers && phoneNumbers[0]) {
    phone = normalizePhone(phoneNumbers[0].value);
  }

  return {
    cfaId: uid,
    firstName: givenName,
    lastName: familyName,
    phone,
    email,
    loyaltyCardNumber,
  };
};

const mapCustomerEmailSearchResults = (unboundCustomer) => {
  const {
    givenName,
    familyName,
    phoneNumber,
    emailAddress,
    id,
  } = unboundCustomer;

  let phone = '';
  if (phoneNumber) {
    phone = normalizePhone(phoneNumber);
  }

  return {
    cfaId: id,
    firstName: givenName,
    lastName: familyName,
    phone,
    email: emailAddress,
  };
};

const mapUnboundPhoneCustomer = (unboundCustomer) => {
  const {
    givenName,
    familyName,
    telephoneNumber,
    Mobile,
    primaryEmail,
    uid,
    loyaltyCardNumber,
  } = unboundCustomer;
  const phone = normalizePhone(telephoneNumber || Mobile);

  return {
    cfaId: uid,
    firstName: givenName,
    lastName: familyName,
    phone,
    email: primaryEmail,
    loyaltyCardNumber,
  };
};

const getGuestSearchResultsFromApi = (
  firstName,
  lastName,
  cfaAccessToken,
  locationNumber,
  options = {},
) => {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/customers/locations/1.0/${locationNumber}/search`;

  const body = { firstName, lastName };
  if (options.cfaOneOnly) {
    body.cfaOneOnly = 'true';
  }

  const mapper = customers => {
    if (customers && customers.length) {
      return customers.map(mapUnboundCustomer);
    }
    return [];
  };
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken,
    },
    body,
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Name Search Results',
    },
  });
};

const getGuestEmailSearchResultsFromApi = (emailAddress, cfaAccessToken) => {
  const baseUrl = ooeConstants.URL.D2C;
  const url = `${baseUrl}/cfa.d2c.customer.v1.CustomerService/FindCustomersByEmailAddress`;

  const mapper = res => {
    if (res.customers && res.customers.length) {
      return res.customers.map(mapCustomerEmailSearchResults);
    }
    return [];
  };
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken,
    },
    body: { emailAddress },
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_D2C,
      context: 'Email Search Results',
    },
  });
};

const getGuestPhoneSearchResultsFromApi = (phone, cfaAccessToken) => {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/users/2.0/phone?phone=${phone}`;

  const mapper = res => {
    if (res.userProfiles && res.userProfiles.length) {
      return res.userProfiles.map(mapUnboundPhoneCustomer);
    }
    return [];
  };
  return request({
    url,
    method: 'GET',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken,
    },
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Phone Search Results',
    },
  });
};

const authenticateOnGuestBehalf = (cfaId, cfaAccessToken) => {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/users/3.1/${cfaId}/masqueradeSession`;
  const body = {
    client_id: 'ooe_masquerade',
    scope: '/users/me+/orders/me+/giftcard/limited/me+/bypassBusinessRules',
  };

  const mapper = data => ({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    expiresIn: data.expires_in,
    expiryTimeMs: Date.now() + data.expires_in * 1000,
  });
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken,
    },
    body,
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Masquerade Session',
    },
  });
};

const refreshToken = (guestRefreshToken) => {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/oauth2/accesstoken`;

  const form = new FormData();
  form.append('grant_type', 'refresh_token');
  form.append('refresh_token', guestRefreshToken);
  form.append('client_id', 'ooe_masquerade');
  const mapper = data => ({
    accessToken: data.access_token,
    expiresIn: data.expires_in,
    expiryTimeMs: Date.now() + data.expires_in * 1000,
  });

  const method = 'POST';
  const bugsnag = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
    context: 'Refresh Guest Session Token',
  };

  return request({
    method,
    url,
    body: form,
    form: true,
    mapper,
    bugsnag,
  });
};

const getPaymentFromApi = (guestAccessToken) => {
  const baseUrl = ooeConstants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.0/me`;
  return request({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PAYMENTS,
      context: 'Get Payment',
    },
  });
};

const lookupPaymentByAccountId = (accountId, guestAccessToken) => {
  const baseUrl = ooeConstants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.0/me/${accountId}`;
  return request({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PAYMENTS,
      context: 'Lookup Payment By Account Id',
    },
  });
};

const initiateGuestSession = (cfaAccessToken) => {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/users/3.0/guest/session`;

  const mapper = data => ({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    expiresIn: data.expires_in,
    expiryTimeMs: Date.now() + data.expires_in * 1000,
  });
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey: cfaAccessToken,
    },
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Initiate Guest Session',
    },
  });
};

const validateZipFromApi = ({ zip, card = {}, apiKey } = {}) => {
  const baseUrl = ooeConstants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.0/me/search`;
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey,
    },
    body: {
      cardAlias: card.accountDisplay,
      cardType: card.cardType,
      zipCode: zip,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PAYMENTS,
      context: 'Validate Zipcode',
    },
  });
};

const profileUpdateApi = (guestAccessToken, guest) => {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/users/2.0/me`;
  return request({
    url,
    method: 'PATCH',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: guest,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Update Guest Profile',
    },
  });
};

const getGuestPastOrders = (guestAccessToken, numToLoad = 3) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/users/1.1/me/history/catering?maximumCount=${numToLoad}`;
  const mapper = pastOrders => pastOrders.map(order => ({
    ...order,
    lineItems: order.lineItems.map(item => ({
      tag: item.itemTag,
      quantity: item.quantity,
      name: item.name,
      comboItems: item.comboItems,
      modifiers: item.modifiers,
    })),
  }));
  return request({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Guest Past Orders',
    },
  });
};

const getFavoriteOrders = (guestAccessToken, locationNumber, destination, isCatering) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites?locationNumber=${locationNumber}&destination=${destination}&isCatering=${isCatering}`;
  return request({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Favorite Orders',
    },
  });
};

const addToFavorites = (orderId, name, guestAccessToken) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites`;
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: {
      orderId,
      name,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Add To Favorites',
    },
  });
};

const removeFromFavorites = (favoriteOrderId, guestAccessToken) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites/${favoriteOrderId}`;
  return request({
    url,
    method: 'DELETE',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Remove From Favorites',
    },
  });
};

const updateFavoriteName = (favoriteOrderId, favoriteName, guestAccessToken) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.1/users/me/favorites/${favoriteOrderId}/name?name=${favoriteName}`;
  return request({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Update Favorite Name',
    },
  });
};

export {
  addToFavorites,
  authenticateOnGuestBehalf,
  getFavoriteOrders,
  getGuestEmailSearchResultsFromApi,
  getGuestPastOrders,
  getGuestPhoneSearchResultsFromApi,
  getGuestSearchResultsFromApi,
  getPaymentFromApi,
  initiateGuestSession,
  lookupPaymentByAccountId,
  profileUpdateApi,
  refreshToken,
  removeFromFavorites,
  updateFavoriteName,
  validateZipFromApi,
};
