import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Box, Flex, Spinner } from '@cfacorp/cowponents';
import { Icon, Loyalty } from '../index';

function DetailViewHeader(props) {
  const {
    dashboardActionLoading,
    orderDetails,
    close,
  } = props;
  const {
    givenName,
    familyName,
    displayPrice,
    displayStatus,
    displayLongDate,
    cfaLoyalty,
    destination,
  } = orderDetails;
  const { statusName, statusIcon } = displayStatus;
  return (
    <StyledDetailViewHeader>
      <Box
        width={[1, 1, 1 / 3]}
        id="display-guest-name"
      >
        <div className="guest-name">{`${givenName} ${familyName}`}</div>
        {cfaLoyalty && <Loyalty showText cfaLoyalty={cfaLoyalty} />}
      </Box>
      <Box
        width={[1, 1, 1 / 6]}
        className="header-details"
        id="display-date-time"
      >
        {displayLongDate}
      </Box>
      <Box
        width={[1, 1, 1 / 6]}
        className="header-details"
        id="display-destination"
      >
        {destination}
      </Box>
      <Box
        width={[1, 1, 1 / 6]}
        className="header-details"
        id="display-price"
      >
        {displayPrice}
      </Box>
      <Flex
        width={1 / 6}
        alignItems="center"
        justifyContent="center"
        className="detail-status"
      >
        { !dashboardActionLoading ? <Icon>{statusIcon}</Icon>
          : <Spinner className="loading" />}
        {statusName}
      </Flex>
      <div role="presentation" className="close" onClick={close}>
        <Icon>close</Icon>
      </div>
    </StyledDetailViewHeader>
  );
}

const StyledDetailViewHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 13px;
  position: relative;
  text-align: center;
  width: unset;
  
  & .guest-name {
    font-weight: bold;
    font-size: 25px;
  }
  & .close svg {
    position: absolute;
    fill: ${(props) => props.theme.outline};
    right: -14px;
    top: -20px;
    width: 21px;
    margin: 0;
  }
  & .close:hover {
    cursor: pointer;
  }
  & .detail-status svg, .loyalty svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }
  & ::after {
    content: "";
    clear: both;
    display: table;
  }
  
  @media(max-width: ${(props) => props.theme.phone}) {
    flex-direction: column;

    & div {
      width: unset;
    }
    & .guest-name {
      margin: 13px;
    }
    & .header-details {
      margin: 3px;
    }
    & .detail-status, .loyalty {
      height: 24px;
      margin-top: -3px;
    }
  }
`;

DetailViewHeader.propTypes = {
  orderDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])),
  close: PropTypes.func.isRequired,
  dashboardActionLoading: PropTypes.bool.isRequired,
};

DetailViewHeader.defaultProps = {
  orderDetails: {},
};

export default DetailViewHeader;
