import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

function Tooltip({ children, tooltipContent, className }) {
  return (
    <StyledTooltip className={className}>
      <div className="tooltip-wrapper">
        {children}
        <div className="tooltip">
          {tooltipContent}
        </div>
      </div>
    </StyledTooltip>
  );
}

const StyledTooltip = styled.div`
  & .tooltip-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  & .tooltip {
    box-shadow: 0px 1px 22px 2px rgba(214,214,214,1);
  }

  & .tooltip-wrapper .tooltip {
    visibility: hidden;
    background-color: white;
    color: ${props => props.theme.text};
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1000;
    top: 5px;
    left: 0px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    min-width: 200px;
  }
  
  & .tooltip-wrapper:hover {
    cursor: pointer;
  }  

  & .tooltip-wrapper:hover .tooltip {
    visibility: visible;
  }
`;

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  className: '',
};

export default Tooltip;
