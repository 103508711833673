import styled from 'styled-components';

const Disclaimer = styled.div.attrs({
  'data-cy': 'disclaimer',
})`
  color: ${props => props.theme.text};
  font: ${props => props.theme.smallTextFont};
  margin-left: 10px;
`;

Disclaimer.displayName = 'Disclaimer';

export default Disclaimer;
