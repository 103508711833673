import styled from 'styled-components/macro';

const Footer = styled.div`
  font: ${props => props.theme.smallTextFont};
  text-align: center;
  /* match body margin px */
  margin-bottom: -75px;
  
  @media(max-width: ${props => props.theme.phone}) {
    display: none;
  }
`;

export default Footer;
