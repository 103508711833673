/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@cfacorp/cowponents';
import FeatureFlagService from '../../services/featureFlags';
import { PastOrdersCard, FavoriteOrder } from '../index';

const showReorderButton = () => FeatureFlagService.variation('reorder', false);

export const PastOrders = props => {
  const {
    addToFavorites,
    removeFromFavorites,
    updateFavoriteName,
    resetFavoriteActionErrors,
    pastAndFavoriteOrders,
    favoritesLoading,
    favoritesError,
    favoriteActionSuccessful,
    initiateEditOrder,
    customer,
    suggestedFavoriteName,
    currentFavoriteNames,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [favoriteOrderId, setFavoriteOrderId] = useState(null);
  const [favoriteName, setFavoriteName] = useState('');

  const handleAddToFavorites = (favName) => addToFavorites(orderId, favName);
  const handleRemoveFromFavorites = () => removeFromFavorites(favoriteOrderId);
  const handleUpdateFavoriteName = (favName) => updateFavoriteName(favoriteOrderId, favName);

  const openModal = (id, favOrderId, name) => {
    setModalIsOpen(true);
    setOrderId(id);
    setFavoriteOrderId(favOrderId);
    setFavoriteName(name);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    resetFavoriteActionErrors();
  };

  if (pastAndFavoriteOrders.length === 0) {
    return null;
  }

  return (
    <Flex justifyContent={['center', 'end']} flexWrap="wrap" m="20px 0">
      {pastAndFavoriteOrders.map((order, idx) => (
        <PastOrdersCard
          /* eslint-disable-next-line */
          key={`${order.id}-${idx}`}
          order={order}
          showReorderButton={showReorderButton}
          handleReorderClick={() => initiateEditOrder(order, customer, true)}
          openFavoriteModal={openModal}
        />
      ))}
      {
        modalIsOpen
        && (
          <FavoriteOrder
            close={closeModal}
            isOpen={modalIsOpen}
            isFavorite={favoriteOrderId !== undefined}
            addToFavorites={handleAddToFavorites}
            removeFromFavorites={handleRemoveFromFavorites}
            favoritesLoading={favoritesLoading}
            favoritesError={favoritesError}
            setFavoriteName={favoriteName}
            updateFavoriteName={handleUpdateFavoriteName}
            favoriteActionSuccessful={favoriteActionSuccessful}
            suggestedFavoriteName={suggestedFavoriteName}
            currentFavoriteNames={currentFavoriteNames}
          />
        )
      }
    </Flex>
  );
};

PastOrders.propTypes = {
  pastAndFavoriteOrders: PropTypes.arrayOf(
    PropTypes.object,
  ),
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  updateFavoriteName: PropTypes.func.isRequired,
  favoritesLoading: PropTypes.bool,
  favoritesError: PropTypes.string,
  favoriteActionSuccessful: PropTypes.bool,
  resetFavoriteActionErrors: PropTypes.func,
  customer: PropTypes.objectOf(
    PropTypes.any,
  ).isRequired,
  initiateEditOrder: PropTypes.func.isRequired,
  suggestedFavoriteName: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
};

PastOrders.defaultProps = {
  pastAndFavoriteOrders: [],
  favoritesLoading: false,
  favoritesError: null,
  favoriteActionSuccessful: false,
  resetFavoriteActionErrors: () => {},
  suggestedFavoriteName: '',
  currentFavoriteNames: [],
};

export default PastOrders;
