import { Button } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';

const CreateInvoiceButton = ({ onPrint, children }) => (
  <Button
    width="calc(100% - 1em)"
    p="0.5em 1em"
    m="0.5em"
    fontSize={2}
    onClick={onPrint}
    variant="secondary"
  >
    {children}
  </Button>
);

CreateInvoiceButton.propTypes = {
  onPrint: PropTypes.func,
  children: PropTypes.string,
};

CreateInvoiceButton.defaultProps = {
  onPrint: () => {},
  children: '',
};

export default CreateInvoiceButton;
