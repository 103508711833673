import { ofType } from 'redux-observable';
import {
  switchMap,
} from 'rxjs/operators';
import { actions } from '../reducers/menu';
import ooe from '../constants';
import { selectLocationNumber, types } from '../reducers/user';
import epicHelper, { epicOptions } from '../util/myEpicHelper';
import {
  getMenuFromApi,
  getNutrition,
} from '../apis';

export const GetPickupMenuEpic = (action$, store) => action$
  .pipe(
    ofType(
      types.GET_USER_LOCATIONS_SUCCESS,
      types.UPDATE_USER_LOCATION,
    ),
    switchMap(() => {
      const state = store.value;
      const location = selectLocationNumber(state);
      return epicHelper(
        getMenuFromApi({
          type: ooe.DXE_CATERING_TYPE,
          destination: ooe.DXE_PICKUP_METHOD,
          location,
        }),
        actions.menuSuccess(ooe.PICKUP),
        actions.menuFailure(ooe.PICKUP),
        epicOptions(store, action$),
      );
    }),
  );

export const GetDeliveryMenuEpic = (action$, store) => action$
  .pipe(
    ofType(
      types.GET_USER_LOCATIONS_SUCCESS,
      types.UPDATE_USER_LOCATION,
    ),
    switchMap(() => {
      const state = store.value;
      const location = selectLocationNumber(state);
      return epicHelper(
        getMenuFromApi({
          type: ooe.DXE_CATERING_TYPE,
          destination: ooe.DXE_DELIVERY_METHOD,
          location,
        }),
        actions.menuSuccess(ooe.DELIVERY),
        actions.menuFailure(ooe.DELIVERY),
        epicOptions(store, action$),
      );
    }),
  );

export const GetNutrition = (action$, store) => action$
  .pipe(
    ofType(
      types.GET_USER_LOCATIONS_SUCCESS,
      types.UPDATE_USER_LOCATION,
    ),
    switchMap(() => epicHelper(
      getNutrition(),
      actions.nutritionSuccess,
      actions.nutritionFailure,
      epicOptions(store, action$),
    )),
  );

export default [
  GetPickupMenuEpic,
  GetDeliveryMenuEpic,
  // GetNutrition, // TODO: 401 for every request
];
