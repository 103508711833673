import { ofType } from 'redux-observable';
import {
  filter,
  tap,
  ignoreElements,
} from 'rxjs/operators';
import { actionTypes as formActionTypes } from 'redux-form';
import {
  types as userTypes,
  selectLocationNumber,
} from '../reducers/user';
import { types as cartTypes } from '../reducers/cart';
import { types as dashboardTypes } from '../reducers/dashboard';
import { types as guestTypes } from '../reducers/guest';
import { types as orderTypes } from '../reducers/order';
import { selectForm } from '../reducers/form';
import {
  logEvent,
  setUser,
  setUserLocation,
  createSubmitOrderAnalytics,
} from '../services/analytics';
import { notifyBugsnag, leaveBreadcrumb } from '../services/bugsnag';
import { roundNumber } from '../util/utils';

export const GenerateBugsnagTestError = (action$, store) => action$
  .pipe(
    ofType(orderTypes.GUEST_COUNT_CHANGED),
    tap(() => {
      const state = store.value;
      const formData = selectForm(state);
      if (formData?.details?.values?.specialInstructions === 'CREATE-BUGSNAG-TEST-ERROR') {
        const testErrorInfo = {
          cfa_perms: state?.user?.cfa_perms,
          cfa_aud: state?.user?.cfa_aud,
        };

        leaveBreadcrumb('Generate Test Error 1', {
          info: testErrorInfo,
        });

        notifyBugsnag('Test Error 1', {
          context: 'Generate Test Error',
          info: testErrorInfo,
        });
      }
    }),
    ignoreElements(),
  );

export const LogCartEvents = (action$) => action$
  .pipe(
    ofType(
      cartTypes.ADD_TO_CART,
      cartTypes.UPDATE_QUANTITY,
      cartTypes.DELETE_ITEM,
      cartTypes.ADD_MODIFIER,
      cartTypes.UPDATE_MODIFIER_QUANTITY,
      cartTypes.UPDATE_SPECIAL_INSTRUCTIONS,
      cartTypes.UPDATE_SIDE_ITEM,
      cartTypes.UPDATE_DESSERT_ITEM,
      cartTypes.MAKE_PROMO_FREE,
      cartTypes.REMOVE_PROMO_FREE,
    ),
    tap((action) => {
      const { type, ...payload } = action;
      logEvent(type, payload);
    }),
    ignoreElements(),
  );

export const LogGuestEvents = (action$) => action$
  .pipe(
    ofType(
      guestTypes.GUEST_SEARCH_SUCCESS,
      guestTypes.GUEST_SEARCH_EMAIL_SUCCESS,
      guestTypes.GUEST_SEARCH_PHONE_SUCCESS,
      guestTypes.GUEST_SELECTED,
      guestTypes.GUEST_VALIDATE_ZIP_SUCCESS,
      guestTypes.GUEST_SELECTED,
      guestTypes.MASQUERADE_GUEST_UNSELECTED,
      guestTypes.ADD_TO_FAVORITES,
      guestTypes.REMOVE_FROM_FAVORITES,
      guestTypes.UPDATE_FAVORITE_NAME,
      guestTypes.GET_PAST_DELIVERY_ADDRESSES,
    ),
    tap((action) => {
      // we don't send the payload for guest events so as to protect sensitive data
      const { type } = action;
      logEvent(type);
    }),
    ignoreElements(),
  );

export const LogDashboardEvents = (action$) => action$
  .pipe(
    ofType(
      dashboardTypes.GET_ORDERS,
      dashboardTypes.GET_ORDERS_FOR_SPECIFIC_DAYS,
      dashboardTypes.CANCEL_ORDER,
      dashboardTypes.LOOKUP_ORDER_DETAILS,
      dashboardTypes.RESEND_PAYMENT_EMAIL,
      dashboardTypes.LOAD_MORE_PAST_ORDERS,
    ),
    tap((action) => {
      const { type, ...payload } = action;
      logEvent(type, payload);
    }),
    ignoreElements(),
  );

export const LogOrderEvents = (action$) => action$
  .pipe(
    ofType(
      orderTypes.CHANGE_DESTINATION,
      orderTypes.DATE_CHANGED,
      orderTypes.TIME_CHANGED,
      orderTypes.INITIATE_EDIT_ORDER,
      orderTypes.EXIT_EDIT_ORDER,
      orderTypes.ADD_DELIVERY_TIP,
    ),
    tap((action) => {
      const { type, ...payload } = action;
      delete payload.guest;
      logEvent(type, payload);
    }),
    ignoreElements(),
  );

export const LogSubmitOrderEvents = (action$, store) => action$
  .pipe(
    ofType(
      orderTypes.SUBMIT_ORDER,
      orderTypes.SUBMIT_ORDER_SUCCESS,
      orderTypes.SUBMIT_ORDER_FAILURE,
    ),
    tap((action) => {
      const state = store.value;
      const { type, ...payload } = action;
      let analytics = createSubmitOrderAnalytics(state);
      if (analytics.error) {
        notifyBugsnag('Create Submit Order Analytics Failed', {
          context: 'Analytics Error',
          info: analytics,
        });
        return;
      }

      // post process the events to incorporate differences in
      // supplied data vs what is in redux at this point and
      // what ends up becoming invalid
      switch (type) {
        // istanbul ignore next
        default:
        case orderTypes.SUBMIT_ORDER:
          delete analytics.pickupMinNotMet; // doesn't seem to be accurate at this point
          break;
        case orderTypes.SUBMIT_ORDER_SUCCESS:
          analytics = {
            ...analytics,
            orderId: payload.order.id,
            taxAmount: payload.order.taxAmount,
            subTotalAmount: payload.order.subTotalAmount,
            reorder: payload.order.reorder,
            totalAmount: roundNumber(
              (payload?.order?.taxAmount || 0)
              + (payload?.order?.subTotalAmount || 0)
              + (payload?.order?.deliveryTipAmount || 0),
            ),
            clientId: payload.response.clientId,
          };
          delete analytics.isEditingOrder;
          delete analytics.destination;
          delete analytics.promoFreeItemCount;
          delete analytics.promoFreeItemsValue;
          break;
        case orderTypes.SUBMIT_ORDER_FAILURE:
          delete analytics.taxAmount;
          delete analytics.subTotalAmount;
          delete analytics.totalAmount;
          delete analytics.reorder;
          delete analytics.pickupMinNotMet;
          delete analytics.isEditingOrder;
          delete analytics.promoFreeItemCount;
          delete analytics.promoFreeItemsValue;
          break;
      }
      logEvent(type, analytics);
    }),
    ignoreElements(),
  );

export const LogFormEvents = (action$) => action$
  .pipe(
    ofType(formActionTypes.BLUR),
    filter(({ meta: { field } }) => (field !== 'email') && (field !== 'phone')),
    tap((action) => {
      const { meta, payload } = action;
      const type = `[${meta.form}] ${meta.field} change`;
      const payloadToPass = { [meta.field]: payload };
      logEvent(type, payloadToPass);
    }),
    ignoreElements(),
  );

export const LogUserAttributes = (action$) => action$
  .pipe(
    ofType(
      userTypes.GET_OKTA_TOKEN,
      userTypes.GET_OKTA_TOKEN_SUCCESS,
      userTypes.GET_OKTA_TOKEN_FAILURE,
      userTypes.PROCESS_OKTA_TOKEN_SUCCESS,
      userTypes.PROCESS_OKTA_TOKEN_FAILURE,
      userTypes.REFRESH_TOKEN,
      userTypes.REFRESH_TOKEN_SUCCESS,
      userTypes.REFRESH_TOKEN_FAILURE,
      userTypes.TOGGLE_BYPASS_BUSINESS_RULES,
      userTypes.LOGOUT_USER,
    ),
    tap((action) => {
      const { type, user } = action;

      if (user?.cfa_guid) {
        setUser(user.cfa_guid);
      }

      logEvent(type);
    }),
    ignoreElements(),
  );

export const SetUserLocation = (action$, store) => action$
  .pipe(
    ofType(
      userTypes.UPDATE_USER_LOCATION,
      userTypes.GET_USER_LOCATIONS_SUCCESS,
    ),
    tap(() => {
      const state = store.value;
      setUserLocation(selectLocationNumber(state));
    }),
    ignoreElements(),
  );

export default [
  LogCartEvents,
  LogGuestEvents,
  LogDashboardEvents,
  LogOrderEvents,
  LogSubmitOrderEvents,
  LogFormEvents,
  LogUserAttributes,
  SetUserLocation,
  GenerateBugsnagTestError,
];
