/* eslint-disable react/no-children-prop */
import { useState } from 'react';
import { Link, Route, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Tab, Icon } from '../index';
import ooeConstants from '../../constants';
import { logPageView } from '../../services/analytics';

export const MoreTab = props => {
  const {
    icon,
    to,
    activeOnlyWhenExact,
    title,
    location,
  } = props;

  const [showDropDown, setShowDropDown] = useState(false);

  const emailDetails = ooeConstants.MAILTO_STRING(location);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const onCloseHandler = () => {
    setShowDropDown(false);
  };

  const renderDropDown = () => (
    <div className={showDropDown ? 'drop-down expand' : 'drop-down'}>
      <ul className="options">
        <li>
          <NavLink to={{ pathname: '/more/dashboard', title: 'Customer Dashboard' }}>
            <div className="link">Customer Dashboard</div>
          </NavLink>
        </li>
        <div className="separator" />
        <li role="presentation" onClick={onCloseHandler}>
          <a
            id="linkNeedHelp"
            target="_blank"
            rel="noopener noreferrer"
            href={ooeConstants.HELPFUL_LINKS.NEED_HELP}
            onClick={() => logPageView('Need Help')}
          >
            Need Help?
          </a>
        </li>
        <div className="separator" />
        <div>
          <div className="links">Helpful Links:</div>
          <li role="presentation" onClick={onCloseHandler}>
            <a id="linkAboutCmt" target="_blank" rel="noopener noreferrer" href={ooeConstants.HELPFUL_LINKS.ABOUT_CMT} onClick={() => logPageView('About CMT')}>About CMT</a>
          </li>
          <li role="presentation" onClick={onCloseHandler}>
            <a id="linkUserGuide" target="_blank" rel="noopener noreferrer" href={ooeConstants.HELPFUL_LINKS.USER_GUIDE} onClick={() => logPageView('User Guide')}>User Guide</a>
          </li>
          <li role="presentation" onClick={onCloseHandler}>
            <a id="linkFAQ" target="_blank" rel="noopener noreferrer" href={ooeConstants.HELPFUL_LINKS.FAQ} onClick={() => logPageView('Frequently Asked Questions')}>Frequently Asked Questions</a>
          </li>
          <li role="presentation" onClick={onCloseHandler}>
            <a id="linkFeedback" rel="noopener noreferrer" href={emailDetails} onClick={() => logPageView('Give Feedback')}>Give Feedback</a>
          </li>
        </div>
      </ul>
      <div
        className="under-mask"
        role="presentation"
        onClick={onCloseHandler}
      />
    </div>
  );

  return (
    <StyledMoreTab>
      <div className="desktop-tab" role="presentation" onClick={toggleDropDown}>
        <Tab>
          <div className="more">
            More
            <Icon className="down">down</Icon>
          </div>
        </Tab>
        { showDropDown && renderDropDown() }
      </div>
      <Route
        path={to}
        exact={activeOnlyWhenExact}
        children={({ match }) => (
          <Link className="mobile-tab" to={to}>
            <Tab className="more-tab" selected={match}>
              <div className="icon">{icon}</div>
              {title}
            </Tab>
          </Link>
        )}
      />
    </StyledMoreTab>
  );
};

const slideDown = keyframes`
  0% { transform: translateY(-100%); visibility: visible; }
  100% { transform: none; }
`;

const StyledMoreTab = styled.div`
  flex: 1;

  & .desktop-tab {
    height: 100%;
    width: 118px;
    @media (max-width: ${props => props.theme.desktop}) {
      width: 80px;
    }
    @media (max-width: ${props => props.theme.phone}) {
      display: none;
    }
  }

  & .link {
    font-weight: bold;
  }
  
  & .icon svg {
    margin: 8px 0 0 0;
    max-width: 35px;
    max-height: 35px;
  }

  & .desktop-tab:hover svg, .desktop-tab:hover button {
    cursor: pointer;
    color: ${props => props.theme.primary};
    fill: ${props => props.theme.primary};
  }
  
  & .drop-down {
    will-change: transform;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 200ms;
  }
  
  .drop-down.expand {
    top: 70px;
    width: 200px;
    animation-name: ${slideDown};
  }
  
  & .drop-down ul {
    list-style-type: none;
    background-color: ${props => props.theme.background};
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: column;
    text-align: left;
  }
  
  & .drop-down li {
    padding: 0;
    margin: 14px 14px;
    font: ${props => props.theme.regularTextFont};
    color: ${props => props.theme.text};
  }
  
  & .drop-down a {
    font: ${props => props.theme.regularTextFont};
    color: ${props => props.theme.text};
    text-decoration: none;
  }
  
  & .drop-down li:hover, .drop-down a:hover {
    cursor: pointer;
    font: ${props => props.theme.regularBoldFont};
    color: ${props => props.theme.primary};
  }
  
  & .more {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  & .down {
    margin: 0;
    width: 20px;
    fill: ${props => props.theme.outline};
    @media (max-width: ${props => props.theme.phone}) {
      display: none;
    }
  }
  
  & .separator {
     border-bottom: 1px solid ${props => props.theme.outline} !important;
     width: calc(100% - 25px);
     margin: 3px auto;
  }
  
  & .links {
    font-size: 12px;
    margin: 14px;
  }
  
  & .under-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: -32px;
    top: -75px;
    z-index: -1;
    transform: translateX(-50%);
  }
  
  & .mobile-tab {
    display: initial;
    @media (min-width: ${props => props.theme.phone}) {
      display: none;
    }
  }
`;

MoreTab.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  to: PropTypes.string,
  activeOnlyWhenExact: PropTypes.bool,
  location: PropTypes.string,
};

MoreTab.defaultProps = {
  title: '',
  to: '',
  activeOnlyWhenExact: false,
  icon: null,
  location: '',
};

export default MoreTab;
