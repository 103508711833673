export const loadLocationsFromStorage = () => {
  const storedLocations = localStorage.getItem('visitedLocations');
  return storedLocations ? JSON.parse(storedLocations) : [];
};

export const updateLocation = (locationNumber, name) => {
  const locations = loadLocationsFromStorage();
  const index = locations.findIndex(loc => loc.locationNumber === locationNumber);

  if (index < 0) {
    locations.push({ locationNumber, name, visits: 1 });
  } else {
    locations[index].visits += 1;
  }

  return localStorage.setItem('visitedLocations', JSON.stringify(locations));
};

export const getTopLocations = (maxNum) => loadLocationsFromStorage()
  .sort((a, b) => b.visits - a.visits)
  .slice(0, maxNum)
  .map(loc => ({ locationNumber: loc.locationNumber, name: loc.name }));
