/* eslint-disable react/jsx-wrap-multilines */
import styled from 'styled-components/macro';
import { prop, propOr } from 'ramda';
import PropTypes from 'prop-types';
import { formatItemName, formatPrice } from '../../util/format';
import Icon from '../Icon';

const MenuItemPrice = ({
  itemPrice,
  variation,
  hidePricing,
}) => {
  const servingText = variation.match(/[Ss]erves (\d+)/);
  const servingTextToDisplay = prop('1', servingText);
  const numItemsText = variation.match(/^\d+[^,<]+/g);
  const numItemsTextToDisplay = propOr('', '0', numItemsText);

  if (hidePricing) {
    return null;
  }

  return (
    <StyledMenuItemPrice>
      <div className="price">{formatPrice(itemPrice)}</div>
      { variation && (
      <div className="serving-info">
        {servingTextToDisplay && (
          <div className="serving-details">
            <Icon className="people-icon">people</Icon>
            <div>{`  ${servingTextToDisplay}`}</div>
          </div>)}
        { servingTextToDisplay && servingTextToDisplay.length && numItemsTextToDisplay && <span className="divider" /> }
        {numItemsTextToDisplay && (
          <div className="quantity-details">
            <div>{formatItemName(numItemsTextToDisplay)}</div>
          </div>)}
      </div>)}
    </StyledMenuItemPrice>
  );
};

const StyledMenuItemPrice = styled.div`
  font: ${props => props.theme.smallTextFont};
  & .price {
    line-height: 18px;
  }
  & .serving-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .serving-details, .quantity-details {
    display: flex;
  }
  & .divider:before {
    content: '\\22EE';
    margin: 0 5px;
  }
  & .people-icon {
    width: 20px;
    height: 15px;
    margin: 0;
  }
  @media (max-width: ${props => props.theme.tablet}) {
    & .divider {
      display: none;
    }
    & .serving-info {
      flex-direction: column;
    }
  }
`;

MenuItemPrice.propTypes = {
  itemPrice: PropTypes.number,
  hidePricing: PropTypes.bool,
  variation: PropTypes.string,
};

MenuItemPrice.defaultProps = {
  hidePricing: false,
  variation: '',
  itemPrice: 0,
};

export default MenuItemPrice;
