import 'react-app-polyfill/ie11';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import FeatureFlagService from './services/featureFlags';
import { setupAmplitude } from './services/amplitude';
import { BrowserRouter } from './util/history';
import './index.css';
import ConnectedApp from './App';
import ooeConstants from './constants';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import initializeStore from './initializeStore';
import redirectToHttpsIfNecessary from './util/redirectToHttpsIfNecessary';
import getBugSnagStateData from './util/bugSnagStateData';
import {
  setupBugsnag,
  leaveBreadcrumb,
  initializeGetBugSnagStateData,
} from './services/bugsnag';
import { BugsnagErrorBoundary, UnrecoverableError } from './components';
import ReduxStore from './util/reduxStore';

redirectToHttpsIfNecessary({
  location: window.location,
  redirectToUrl: url => {
    window.location.href = url;
  },
});

const store = initializeStore();
ReduxStore.store = store;

try {
  FeatureFlagService.initialize(ooeConstants.URL.CMT_CONFIG).subscribe();
} catch (e) {
  // eslint-disable-next-line no-console
  console.warn('FeatureFlagService.initialize error', e);
}

// set up bugsnag
initializeGetBugSnagStateData(getBugSnagStateData);
setupBugsnag(store);

leaveBreadcrumb('Application Startup', {
  message: 'Application initializing',
});

setupAmplitude();

const render = Component => {
  const root = createRoot(document.getElementById('root'));
  return root.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <BugsnagErrorBoundary FallbackComponent={UnrecoverableError}>
      <Provider store={store}>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </Provider>
    </BugsnagErrorBoundary>,
  );
};

render(ConnectedApp);

unregisterServiceWorker();
