import styled from 'styled-components';

const Tab = styled.button`
  font: ${props => props.theme.smallTextFont};
  color: ${props => (props.selected ? props.theme.primary : props.theme.outline)};
  background-color: ${props => props.theme.background};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  border: none;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background: radial-gradient(circle, #000 25%, transparent 10%) no-repeat 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
  
  &:hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    color: ${props => props.theme.primary};
    fill: ${props => props.theme.primary};
  }
  & svg {
    fill: ${props => (props.selected ? props.theme.primary : props.theme.outline)};
    margin: 10px 10px 0 10px;
  }
  
  & svg:hover {
    fill: ${props => props.theme.primary};
  }
  
  @media (min-width: ${props => props.theme.phone}) {
    font: ${props => props.theme.regularBoldFont};
    border-bottom: 2px solid ${props => (props.selected ? props.theme.primary : props.theme.background)};
  }
`;

export default Tab;
