import Modal from 'react-modal';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function OverlayModal(props) {
  const {
    className,
    isOpen,
    close,
    children,
  } = props;
  Modal.setAppElement('#root');
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      onRequestClose={close}
      isOpen={isOpen}
    >
      {children}
    </Modal>
  );
}

const StyledOverlayModal = styled(OverlayModal)`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: inherit;
  }
  &__content {
    position: absolute;
    top: 50%;
    left:50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    font-size: 14px;
    font-family: ${props => props.theme.fontFamily};
    max-height: 90vh;
    overflow-y: scroll;
  }
  
  @media(max-width: ${props => props.theme.phone}) {
    &__content {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-right: 0;
      max-width: 100%;
      transform: none;
      position: fixed;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 100vh;
    }
  }
`;

OverlayModal.propTypes = {
  className: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

OverlayModal.defaultProps = {
  isOpen: false,
};

export default StyledOverlayModal;
