/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-else-return */
import {
  Box,
  Flex,
  Select,
} from '@cfacorp/cowponents';
import {
  addMinutes, format, isAfter, isBefore, parse,
} from 'date-fns';
import PropTypes from 'prop-types';
import { path, pathOr } from 'ramda';
import { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components/macro';
import constants from '../../constants';
import { formatTimePickerTime, generateTimeSlots } from '../../util/format';
import { validateDetails, validateGuestCount } from '../../util/validate';
import {
  DatePickerInput, Disclaimer, LeadTimeWarning, Textarea, ValidatedField,
} from '../index';
import SelectCateringOccasion from '../SelectCateringOccasion/SelectCateringOccasion';
import wrapComponentFormField from './wrapComponentFormField';

let serviceChannelHours;
let timeSlotsArr = [];

const getTimeSlots = () => timeSlotsArr;

function isInBlackoutWindow(slotTime, endSlotTime, blackoutStart, blackoutMinutes) {
  const blackoutEndTime = addMinutes(blackoutStart, blackoutMinutes);
  return isBefore(slotTime, blackoutEndTime) && isAfter(endSlotTime, blackoutStart);
}

function fillTimeSlotsArr(
  openTime,
  endTime,
  blackoutStart = parse('00:00', constants.DATE_TIME_FORMAT.time, new Date()),
  blackoutMinutes = 0,
  nextSlot = 15,
) {
  timeSlotsArr = [];
  let currentSlotOpenTime = openTime;
  while (!isAfter(currentSlotOpenTime, endTime)) {
    const currentSlotEndTime = addMinutes(currentSlotOpenTime, nextSlot);
    if (
      !isInBlackoutWindow(currentSlotOpenTime, currentSlotEndTime, blackoutStart, blackoutMinutes)
    ) {
      timeSlotsArr.push(format(currentSlotOpenTime, constants.DATE_TIME_FORMAT.time));
    }
    currentSlotOpenTime = addMinutes(currentSlotOpenTime, nextSlot);
  }
}

function getServiceChannelHours(hoursOfOperation, specialEvents = [], pickedDate) {
  const date = format(pickedDate, constants.DATE_TIME_FORMAT.date);
  const dayOfWeek = format(pickedDate, 'EEEE').toLocaleLowerCase();
  const operationType = path([dayOfWeek, 'operationType'], hoursOfOperation);
  const operatingInterval = path([dayOfWeek, 'operatingInterval'], hoursOfOperation);
  const holidays = path(['holidays'], hoursOfOperation);
  const holidayDay = holidays?.filter((h) => h.holidayDate === date);

  const specialEventDay = specialEvents?.find((s) => {
    const sd = parse(s.startDate, constants.DATE_TIME_FORMAT.date, new Date());
    const ed = parse(s.endDate, constants.DATE_TIME_FORMAT.date, new Date());
    const isSpecialEvent = !isAfter(sd, pickedDate) && !isBefore(ed, pickedDate);
    return !!isSpecialEvent;
  });

  if (holidayDay?.length > 0) {
    const [holiday] = holidayDay;
    const { holidayHours } = holiday;
    // eslint-disable-next-line no-shadow
    const { operationType, operatingInterval } = holidayHours;

    if (operationType !== 'closed') {
      const { openTime, durationInMinutes } = operatingInterval;
      const parsedOpenTime = parse(openTime, constants.DATE_TIME_FORMAT.time, new Date());
      const endTime = addMinutes(parsedOpenTime, durationInMinutes);
      fillTimeSlotsArr(parsedOpenTime, endTime);
    } else {
      timeSlotsArr = [];
    }
  } else if (specialEventDay) {
    // eslint-disable-next-line no-shadow
    const { operationType, operatingInterval } = specialEventDay.specialEventHours;

    if (operationType === 'standardHours') {
      const { openTime, durationInMinutes } = operatingInterval;
      const parsedOpenTime = parse(openTime, constants.DATE_TIME_FORMAT.time, new Date());
      const endTime = addMinutes(parsedOpenTime, durationInMinutes);
      fillTimeSlotsArr(parsedOpenTime, endTime);
    } else {
      timeSlotsArr = [];
    }
  } else if (operationType === 'standardHours') {
    const { openTime, durationInMinutes } = operatingInterval;
    const parsedOpenTime = parse(openTime, constants.DATE_TIME_FORMAT.time, new Date());
    const endTime = addMinutes(parsedOpenTime, durationInMinutes);
    const blackoutHours = pathOr(
      { openTime: '00:00', durationInMinutes: 0 },
      [dayOfWeek, 'blackoutHours'],
      hoursOfOperation,
    );
    fillTimeSlotsArr(
      parsedOpenTime,
      endTime,
      parse(blackoutHours.openTime, constants.DATE_TIME_FORMAT.time, new Date()),
      blackoutHours.durationInMinutes,
    );
  } else if (operationType === 'open24Hours') {
    fillTimeSlotsArr(
      parse('00:00', constants.DATE_TIME_FORMAT.time, new Date()),
      parse('23:45', constants.DATE_TIME_FORMAT.time, new Date()),
    );
  } else {
    timeSlotsArr = [];
  }

  return timeSlotsArr.map((t) => ({ time: t, available: true }));
}

function renderAvailableTimes(times, bypassBusinessRules, dateIsToday, timeZone) {
  const timeSlots = bypassBusinessRules ? generateTimeSlots(dateIsToday, timeZone) : times;
  return timeSlots?.map((timeSlot) => {
    let formattedTime = formatTimePickerTime(timeSlot.time);
    const timeUnavailable = !timeSlot.available;
    if (timeUnavailable) {
      formattedTime += ' (Time is currently unavailable)';
    }
    return (
      <option
        className="time-option"
        key={formattedTime}
        value={timeSlot.time}
        disabled={timeUnavailable}
      >
        {formattedTime}
      </option>
    );
  });
}

function timesPlaceholder(timeSlotsData, bypassBusinessRules) {
  if (bypassBusinessRules) {
    return 'Select Time';
  }
  if (!timeSlotsData.length) {
    return 'There are no available times for this order date.';
  }
  return 'Select Time';
}

export function DetailsForm({
  availableDates,
  selectedDate,
  change,
  dateChanged,
  timeChanged,
  availableDatesLoading,
  guestCountChanged,
  bypassBusinessRules,
  dateIsToday,
  timeZone,
  leadTimeWarning,
  hoursOfOperation,
  specialEvents,
  cateringReason: cateringOccasionValue,
  timeShouldClear,
  resetTimeFormValues,
}) {
  /* Start with why:
     Because redux form is dumb, and dispatches the onChange event before
     the change action creator, we were getting unexpected behavior
     when trying to predict state when the dateChanged or timeChanged
     actions had been dispatched. This makes it much more predictable
     as the state is updated before the action.

     TODO: remove redux-form
   */
  useEffect(() => {
    if (timeShouldClear) {
      resetTimeFormValues();
    }
  }, [timeShouldClear]);

  const handleDateChange = (date) => {
    change('date', date);
    dateChanged(date);
    serviceChannelHours = getServiceChannelHours(hoursOfOperation, specialEvents, date);
  };

  const handleTimeChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    change('time', value);
    timeChanged();
  };

  const handleCateringOccasion = (event) => {
    const { value } = event.target;
    change('cateringReason', value);
  };

  return (
    <StyledDetailsForm>
      {!bypassBusinessRules && (
        <Disclaimer>Unavailable dates and times are blocked due to settings in DOP</Disclaimer>
      )}
      <Flex display="flex" flexWrap="wrap" m="0 0 20px 0">
        <Box width={[1, 1 / 2]}>
          <Field
            className="date"
            name="date"
            component={DatePickerInput}
            onChange={handleDateChange}
            availableDates={availableDates}
            loading={bypassBusinessRules ? false : availableDatesLoading}
            bypassBusinessRules={bypassBusinessRules}
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          {(selectedDate || bypassBusinessRules) && (
            <Field
              className="time"
              name="time"
              type="time"
              m="10px 10px 0 10px"
              component={wrapComponentFormField(Select)}
              onChange={handleTimeChange}
              placeholder={timesPlaceholder(timeSlotsArr, bypassBusinessRules)}
              disabled={bypassBusinessRules ? false : !timeSlotsArr.length}
            >
              {renderAvailableTimes(
                serviceChannelHours,
                bypassBusinessRules,
                dateIsToday,
                timeZone,
              ) || <option disabled>There are no available times for this order date.</option>}
            </Field>
          )}
          <div className="lead-time-wrapper">
            <LeadTimeWarning message={leadTimeWarning.message} />
          </div>
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 2]}>
          <Field
            label="Guest Count"
            className="guestCount"
            name="guestCount"
            component={ValidatedField}
            type="number"
            placeholder="Guest Count"
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} // https://stackoverflow.com/a/70084470
            onChange={guestCountChanged}
            normalize={validateGuestCount}
            m="0"
          />
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <SelectCateringOccasion
            handleCateringOccasion={handleCateringOccasion}
            cateringOccasionValue={cateringOccasionValue}
          />
        </Box>
      </Flex>
      <Flex width="calc(100% - 14px)" m="10px 10px 0 10px" fontSize="12px">
        Any special instructions written here will show on the email receipt the customer receives
        upon order submission.
      </Flex>
      <Field
        className="specials"
        name="specialInstructions"
        component={Textarea}
        type="notes"
        placeholder="Special Instructions"
        maxLength={144}
      />
    </StyledDetailsForm>
  );
}

const StyledDetailsForm = styled(Box)`
  & .specials {
    width: calc(100% - 14px);
  }

  & input {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.outline};
    height: 50px;
    box-sizing: border-box;
    margin: 10px;
    width: calc(100% - 14px);
    appearance: none;
    padding-left: 10px;
  }

  & input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    opacity: 0;
  }

  & input[type='number']:hover::-webkit-inner-spin-button,
  input[type='number']:hover::-webkit-outer-spin-button,
  input[type='number']:focus::-webkit-inner-spin-button,
  input[type='number']:focus::-webkit-outer-spin-button {
    opacity: 1;
  }

  & .lead-time-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    @media (max-width: ${(props) => props.theme.phone}) {
      position: relative;
    }
  }

  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

DetailsForm.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  dateChanged: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  availableDatesLoading: PropTypes.bool,
  guestCountChanged: PropTypes.func.isRequired,
  bypassBusinessRules: PropTypes.bool,
  dateIsToday: PropTypes.bool,
  timeChanged: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  leadTimeWarning: PropTypes.objectOf(PropTypes.string).isRequired,
  hoursOfOperation: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
  specialEvents: PropTypes.array,
  cateringReason: PropTypes.string,
  timeShouldClear: PropTypes.bool,
  resetTimeFormValues: PropTypes.func,
};

DetailsForm.defaultProps = {
  availableDatesLoading: true,
  bypassBusinessRules: false,
  dateIsToday: false,
  hoursOfOperation: {},
  specialEvents: [],
  cateringReason: '',
  timeShouldClear: false,
  resetTimeFormValues: () => { },
};

export const exportsForTesting = {
  isInBlackoutWindow,
  getTimeSlots,
  fillTimeSlotsArr,
  getServiceChannelHours,
};

export default reduxForm({
  form: constants.GET_FORM_TYPES.DETAILS,
  destroyOnUnmount: false,
  validate: validateDetails,
  initialValues: { date: '' },
})(DetailsForm);
