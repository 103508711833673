/* eslint-disable react/forbid-prop-types */

import { Button, Spinner } from '@cfacorp/cowponents';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Disclaimer, Icon,
} from '../index';
import ooeConstants from '../../constants';

function SubmitOrderButton({
  selectedMethod,
  submitWarnings,
  isDisabled,
  submitOrder,
  submitOrderLoading,
  isEditMode,
}) {
  const buttonText = () => {
    if (isEditMode) {
      return 'Save Changes and Update Order';
    }
    if (selectedMethod === ooeConstants.REQUEST_PAYMENT) {
      return 'Send Email';
    }
    return 'Submit Order';
  };
  return (
    <>
      {submitWarnings && submitWarnings.map((warning) => (
        <Disclaimer data-testid="disclamer" key={warning.message}>
          <DisclaimerIcon>{warning.type}</DisclaimerIcon>
          {warning.message}
        </Disclaimer>
      ))}
      {submitOrderLoading ? <Spinner /> : (
        <Button
          width="calc(100% - 1em)"
          p="0.5em 1em"
          m="0.5em"
          fontSize={2}
          data-cy="order-submit"
          disabled={isDisabled}
          onClick={submitOrder}
          style={{ marginBottom: '80px' }}
        >
          {buttonText()}
        </Button>
      )}
    </>
  );
}

const DisclaimerIcon = styled(Icon)`
  height: 15px;
  width: 15px;
  margin: 2px 7px -2px 0;
`;

SubmitOrderButton.propTypes = {
  selectedMethod: PropTypes.string,
  submitWarnings: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  submitOrder: PropTypes.func,
  submitOrderLoading: PropTypes.bool,
  isEditMode: PropTypes.bool,
};

SubmitOrderButton.defaultProps = {
  selectedMethod: '',
  submitWarnings: [],
  isDisabled: false,
  submitOrder: () => {},
  submitOrderLoading: false,
  isEditMode: false,
};

export default SubmitOrderButton;
