import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

const history = createBrowserHistory();

export const BrowserRouter = ({ children }) => (
  <Router history={history}>
    { children }
  </Router>
);
BrowserRouter.propTypes = {
  children: PropTypes.element,
};
BrowserRouter.defaultProps = {
  children: null,
};

export default history;
