/* eslint-disable react/forbid-prop-types */
import { memo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { SectionHeader, Section, Input } from '@cfacorp/cowponents';
import { StyledMenuItem } from '../components';
import { selectCart, actions as cartActions } from '../reducers/cart';
import {
  actions as orderActions, selectDestination, selectEditMode, selectIsEditCMTOrder, selectPaperGoodsBool, selectPaperGoodsOptions, selectPaperGoodsYesOrNo,
} from '../reducers/order';
import { selectMenuWithQuantities } from '../reducers';
import { selectNutrition } from '../reducers/menu';
import PaperGoods from '../components/PaperGoods/PaperGoods';

export const Menu = ({
  menu,
  addToCart,
  nutrition,
  cartItems,
  setPaperGoodsOptions,
  paperGoodsOptions,
  paperGoodsYesOrNo,
  isEditMode,
  isCMTOrder,
  paperGoodsBool,
}) => {
  const [filteredText, setFilteredText] = useState('');

  const filterItemsByText = (item) => item.name && item.name.toLowerCase()
      .includes(filteredText.toLowerCase());

  const filterCategoriesByText = (cat) => cat.items
  .filter(filterItemsByText).length;

  const onFilterChange = e => {
    setFilteredText(e.target.value);
  };

  return (
    <div style={{
      maxWidth: 1010, marginLeft: 'auto', marginRight: 'auto', position: 'relative',
    }}
    >
      <Section>
        <SectionHeader>Paper Goods?</SectionHeader>
        <PaperGoods
          setPaperGoodsOptions={setPaperGoodsOptions}
          paperGoodsOptions={paperGoodsOptions}
          paperGoodsYesOrNo={paperGoodsYesOrNo}
          isEditMode={isEditMode}
          isCMTOrder={isCMTOrder}
          paperGoodsBool={paperGoodsBool}
        />
      </Section>
      <FilterInput
        onChange={onFilterChange}
        value={filteredText}
        type="search"
        autoFocus
        placeholder="Filter by…"
        className="filter-input"
      />
      {menu
        .filter(filterCategoriesByText)
        .map((cat) => (
          <Section key={cat.tag}>
            <SectionHeader>{cat.name}</SectionHeader>
            <Items data-cy={`${cat.name}-menu-category`}>
              {cat.items
                .filter(filterItemsByText)
                .map((item) => (
                  <StyledMenuItem
                    item={item}
                    quantity={
                      cartItems
                        .filter((cartItem) => cartItem.tag === item.tag)
                        .reduce((count, cartItem) => count + cartItem.quantity, 0)
                    }
                    nutrition={nutrition[item.tag]}
                    addToCart={addToCart}
                    key={item.tag}
                    hideQuantityInput
                    className="menu-item"
                  />
                ))}
            </Items>
          </Section>
        ))}
    </div>
  );
};

export const FilterInput = styled(Input)`
  @media (min-width: 500px) {
    position: absolute;
    right: 22px;
  }
  float: right;
  height: 40px;
  width: 150px;
  padding: 7px;
  margin-top: 12px;
`;

const Items = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

Menu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object),
  nutrition: PropTypes.objectOf(PropTypes.any),
  addToCart: PropTypes.func.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object),
  setPaperGoodsOptions: PropTypes.func,
  paperGoodsOptions: PropTypes.objectOf(PropTypes.bool),
  paperGoodsYesOrNo: PropTypes.string,
  isEditMode: PropTypes.bool,
  isCMTOrder: PropTypes.bool,
  paperGoodsBool: PropTypes.bool,
};

Menu.defaultProps = {
  menu: [],
  nutrition: {},
  cartItems: [],
  setPaperGoodsOptions: () => {},
  paperGoodsOptions: {},
  paperGoodsYesOrNo: '',
  isEditMode: false,
  isCMTOrder: false,
  paperGoodsBool: false,
};

function mapStateToProps(state) {
  return {
    menu: selectMenuWithQuantities(state),
    destination: selectDestination(state),
    nutrition: selectNutrition(state),
    cartItems: selectCart(state),
    paperGoodsOptions: selectPaperGoodsOptions(state),
    paperGoodsYesOrNo: selectPaperGoodsYesOrNo(state),
    isEditMode: selectEditMode(state),
    isCMTOrder: selectIsEditCMTOrder(state),
    paperGoodsBool: selectPaperGoodsBool(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...cartActions, ...orderActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(Menu));
