/* eslint-disable max-classes-per-file */
import { statusCodes } from './customerErrorMessages';
import { notifyBugsnag } from '../services/bugsnag';

export class ExtendableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}

export class NeedsRefreshError extends ExtendableError {
  constructor(message) {
    super(message); // (1)
    this.name = 'NeedsRefreshError';
    this.needsRefresh = true;
  }
}

export class MappedError extends ExtendableError {
  constructor(message, type) {
    super(message); // (1)
    this.name = type;
  }
}

export default function handleErrors(response,
  bugsnagData = { breadcrumb: 'API Call Error', errorClass: response.url, context: 'API Call Error' }) {
  if (!response.ok) {
    try {
      notifyBugsnag(bugsnagData.errorClass, {
        response,
        context: bugsnagData.context,
        breadcrumb: bugsnagData.breadcrumb,
        info: bugsnagData.info,
      });

      return response.json()
        .then((e) => {
          let errString;
          let customerErr;
          let errorCode;

          const statusText = response.statusText || e.error;
          if (e.message || e.statusMessage || e.statusCode) {
            customerErr = e.statusMessage;
            errString = `${response.status} - ${statusText} (${e.message})`;
            errorCode = e.statusCode;
          } else {
            errString = `${response.status} - ${statusText}`;
          }
          // eslint-disable-next-line no-console
          console.error(errString);
          if (response.status === 401) {
            throw new NeedsRefreshError(customerErr);
          }
          /* istanbul ignore if */
          if (statusCodes[errorCode]) {
            customerErr = statusCodes[errorCode];
            const { message, type } = customerErr;
            throw new MappedError(message, type);
          }
          throw new Error(customerErr);
        });
    } catch (e) {
      notifyBugsnag(e, {
        response,
        context: `API Call Error Parsing JSON - ${bugsnagData.context}`,
        breadcrumb: bugsnagData.breadcrumb,
        info: bugsnagData.info,
      });

      return response;
    }
  }
  return response.text()
    .then((text) => {
      if (text.length) {
        try {
          const parsedText = JSON.parse(text);
          return parsedText;
        } catch (e) {
          notifyBugsnag(e, {
            response,
            context: `API Call Error parsing JSON text - ${bugsnagData.context}`,
            breadcrumb: bugsnagData.breadcrumb,
            info: bugsnagData.info,
          });

          // eslint-disable-next-line no-console
          console.info(text);
          throw e;
        }
      }
      return '';
    });
}

export function handleFetchFail(response) {
  return new Promise(() => {
    const { message, type } = statusCodes[1301];

    // eslint-disable-next-line no-console
    console.error('handleFetchFail hit , response=', response);
    // eslint-disable-next-line no-console
    console.info('message=', message);
    // eslint-disable-next-line no-console
    console.info('type=', type);

    notifyBugsnag('Fetch Failure', { message, type, fetchFailResponse: response });

    throw new MappedError(message, type);
  });
}
