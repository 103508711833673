/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { formatPrice, formatRestaurantLocalTime } from '../../util/format';
import WorkingHours from './WorkingHours';

const StoreInformation = React.forwardRef((props, ref) => {
  const {
    pickupLeadTime,
    deliveryLeadTime,
    deliveryRange,

    minDeliveryAmount,
    minPickupAmount,
    maxDeliveryAmount,
    maxPickupAmount,
    maxSameDayOrderTotalAmountCents,
    inStateDeliveryOnly,
    allDayChickenMinis,
    storeInfoSpecialMessage,
    restaurantWorkingHours,
    cateringDeliveryWorkingHours,
    restaurantPhone,
    locationTimezone,
  } = props;
  let minTotalAmount;
  let maxTotalAmount;
  let maxFutureDayCount;

  if (minDeliveryAmount === minPickupAmount) {
    minTotalAmount = <p className="minTotalAmount">Minimum total amount: <span>{formatPrice(minDeliveryAmount / 100)}</span></p>;
  } else {
    minTotalAmount = (
      <>
        {minDeliveryAmount !== 0 ? (<p className="minDeliveryAmount">Minimum delivery total amount: <span>{formatPrice(minDeliveryAmount / 100)}</span></p>) : ''}
        {minPickupAmount !== 0 ? (<p className="minPickupAmount">Minimum pickup total amount: <span>{formatPrice(minPickupAmount / 100)}</span></p>) : ''}
      </>
    );
  }

  if (maxDeliveryAmount === maxPickupAmount) {
    maxTotalAmount = <p className="maxTotalAmount">Maximum total amount: <span>{formatPrice(maxDeliveryAmount / 100)}</span></p>;
  } else {
    maxTotalAmount = (
      <>
        {maxDeliveryAmount !== 0 ? (<p className="maxDeliveryAmount">Maximum delivery total amount: <span>{formatPrice(maxDeliveryAmount / 100)}</span></p>) : ''}
        {maxPickupAmount !== 0 ? (<p className="maxPickupAmount">Maximum pickup total amount: <span>{formatPrice(maxPickupAmount / 100)}</span></p>) : ''}
      </>
    );
  }
  return (
    <StyledStoreInformation className="styled-store-info" ref={ref}>
      {(restaurantWorkingHours.length !== 0 || cateringDeliveryWorkingHours.length !== 0)
      && (
        <WorkingHours
          restaurantWorkingHours={restaurantWorkingHours}
          cateringDeliveryWorkingHours={cateringDeliveryWorkingHours}
        />
      )}
      {locationTimezone !== '' ? (<p className="localTime">Local Time: <span>{formatRestaurantLocalTime(locationTimezone)}</span></p>) : ''}
      {deliveryLeadTime !== 0 ? (<p className="deliveryLead">Delivery lead time: <span>{deliveryLeadTime / 60}hr</span></p>) : ''}
      {pickupLeadTime !== 0 ? (<p className="pickupLead">Pickup lead time: <span>{pickupLeadTime / 60}hr</span></p>) : ''}
      {deliveryRange !== 0 ? (<p className="deliveryRange">Delivery Range: <span>{deliveryRange}mi</span></p>) : ''}
      {minTotalAmount}
      {maxTotalAmount}
      {maxSameDayOrderTotalAmountCents !== 0 ? (<p className="maxSameDayOrderTotalAmountCents">Maximum same day pickup amount: <span>{formatPrice(maxSameDayOrderTotalAmountCents / 100)}</span></p>) : ''}
      {maxFutureDayCount}
      {inStateDeliveryOnly && (
        <p className="inStateDeliveryOnly">In state delivery only!</p>
      )}
      {restaurantPhone !== '' && (
        <p className="restaurantPhone">Restaurant Phone: <a href={`tel:${restaurantPhone}`}>{restaurantPhone}</a></p>
      )}
      {allDayChickenMinis !== '' && (
        <p className="allDayChichenMinis">{allDayChickenMinis}</p>
      )}
      {storeInfoSpecialMessage !== '' && (
        <p className="storeInfoSpecialMessage">{storeInfoSpecialMessage}</p>
      )}
    </StyledStoreInformation>
  );
});

export const StyledStoreInformation = styled('div')`
    background-color: ${props => props.theme.secondary};
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    & p {
        margin: 3px 0;
    }
    & span {
        font: ${props => props.theme.regularBoldFont}
    }
    & .restaurantPhone a {
      text-decoration: none;
      font: ${props => props.theme.regularBoldFont};
      color: ${props => props.theme.text};
    }
    
`;

StoreInformation.propTypes = {
  pickupLeadTime: PropTypes.number,
  deliveryLeadTime: PropTypes.number,
  deliveryRange: PropTypes.number,

  minDeliveryAmount: PropTypes.number,
  minPickupAmount: PropTypes.number,
  maxDeliveryAmount: PropTypes.number,
  maxPickupAmount: PropTypes.number,
  maxSameDayOrderTotalAmountCents: PropTypes.number,
  inStateDeliveryOnly: PropTypes.bool,
  allDayChickenMinis: PropTypes.string,
  storeInfoSpecialMessage: PropTypes.string,
  restaurantWorkingHours: PropTypes.arrayOf(PropTypes.string),
  cateringDeliveryWorkingHours: PropTypes.arrayOf(PropTypes.string),
  restaurantPhone: PropTypes.string,
  locationTimezone: PropTypes.string,
};

StoreInformation.defaultProps = {
  pickupLeadTime: 0,
  deliveryLeadTime: 0,
  deliveryRange: 0,

  minDeliveryAmount: 0,
  minPickupAmount: 0,
  maxDeliveryAmount: 0,
  maxPickupAmount: 0,
  maxSameDayOrderTotalAmountCents: 0,
  inStateDeliveryOnly: false,
  allDayChickenMinis: '',
  storeInfoSpecialMessage: '',
  restaurantWorkingHours: [],
  cateringDeliveryWorkingHours: [],
  restaurantPhone: '',
  locationTimezone: '',
};

export default StoreInformation;
