/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Flex } from '@cfacorp/cowponents';
import {
  OrderSummaryModifiers,
  EmptyCart,
} from '../index';
import { formatPrice, formatItemName } from '../../util/format';
import { consolidateItems } from '../../util/menuItemHelpers';

function OrderSummary({
  className,
  cartItems,
  showPrices,
  showModifiers,
}) {
  const consolidatedLineItems = consolidateItems(cartItems);
  return (
    <StyledOrderSummary className={className}>
      {cartItems.length === 0
        ? <EmptyCart className="empty-cart" message="No items in cart" />
        : (
          <>
            <Flex>
              <Box
                mb="5px"
                width={7 / 12}
                fontWeight="bold"
              >
                Item name
              </Box>
              <Box
                textAlign="center"
                mb="5px"
                fontWeight="bold"
                width={showPrices ? (3.5 / 12) : (5 / 12)}
              >
                Quantity
              </Box>
              {showPrices && (
                <Box
                  width={1.5 / 12}
                  textAlign="right"
                  mb="5px"
                  fontWeight="bold"
                >
                  Price
                </Box>
              )}
            </Flex>
            {consolidatedLineItems.map((item) => (
              <Box mb="5px" key={item.id || Math.random() * 5}>
                <Flex lineHeight="27px">
                  <Box data-testid="item-name" width={7 / 12} textAlign="left" data-cy={`${item.tag}-name`}>{formatItemName(item.name)}</Box>
                  <Box data-testid="item-quantity" width={3.5 / 12} textAlign="center" data-cy={`${item.tag}-quantity`}>{item.quantity}</Box>
                  {showPrices && (
                    <Box data-testid="item-price" width={1.5 / 12} textAlign="right" data-cy={`${item.tag}-price`}>
                      {item.promoFree ? 'Promo Free' : formatPrice(item.price)}
                    </Box>
                  )}
                </Flex>
                {showModifiers && <OrderSummaryModifiers data-testid="summary-moddifiers" data-cy={`${item.tag}-modifiers`} item={item} side={item.selectedSide} dessert={item.selectedDessert} showPrices={showPrices} promoFree={item.promoFree} />}
              </Box>
            ))}
          </>
        )}
    </StyledOrderSummary>
  );
}

const StyledOrderSummary = styled.div`
  font: ${(props) => props.theme.regularTextFont};
  & .empty-cart {
    margin: 0;
    padding-bottom: 0;
  }
`;

OrderSummary.propTypes = {
  className: PropTypes.string,
  cartItems: PropTypes.arrayOf(PropTypes.object),
  showPrices: PropTypes.bool,
  showModifiers: PropTypes.bool,
};

OrderSummary.defaultProps = {
  className: '',
  cartItems: [],
  showPrices: true,
  showModifiers: true,
};

export default OrderSummary;
