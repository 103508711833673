import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Box, Flex } from '@cfacorp/cowponents';
import ooeConstants from '../../constants';

export function NavigationComponent(props) {
  const {
    className,
    children,
    leftMobile,
    leftDesktop,
    rightMobile,
    rightDesktop,
    pathname,
  } = props;
  const title = ooeConstants.GET_TITLE[pathname];
  return (
    <div className={className}>
      <Flex className="mobile-header">
        <Box width={2.5 / 12}>{leftMobile}</Box>
        <Box width={7 / 12} textAlign="center"><h2 className="title">{title}</h2></Box>
        <Box width={2.5 / 12}>{rightMobile}</Box>
      </Flex>
      <div className="desktop-navigation">
        <Box width={[0, 0, 1 / 12, 1 / 6]}>{leftDesktop}</Box>
        <Box width={[1 / 2, 1 / 2, 1 / 2, 5 / 12]} className="desktop-center">{children}</Box>
        <Box width={1 / 2} className="desktop-right">{rightDesktop}</Box>
      </div>
      <div className="mobile-navigation">
        { children }
      </div>
    </div>
  );
}

const StyledNavigation = styled(NavigationComponent)`
  position: sticky;
  top: 0;
  z-index: 1500;
  
  & .mobile-header, .desktop-navigation, .mobile-navigation {
    height: 75px;
    z-index: 1000;
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.primary};
    background-color: ${(props) => props.theme.background};
    width: 100%;
  }

  & .mobile-header {
    display: none;
      
    @media (max-width: ${(props) => props.theme.phone}) {
      & .title {
        font-size: 19px;
        margin-top: 0;
        color: ${(props) => props.theme.text};
      }
      display: flex;
      align-items: center;
    }
  }
  
  & .mobile-navigation {
    display: none;
  
    @media (max-width: ${(props) => props.theme.phone}) {
      display: block;
      position: fixed;
      left: 0;
      bottom: 0;
      padding-bottom: env(safe-area-inset-bottom);
    }
   }
 
  & .desktop-navigation {
    display: flex;
    align-items: center;
  
    @media (max-width: ${(props) => props.theme.phone}) {
      display: none;
    }
  }
  
  & .desktop-right {
    text-align: right;
  }
  
  & .desktop-center {
    height: 100%;
  }
  
  & svg {
    fill: ${(props) => props.theme.primary};
  }
  
  & svg:hover {
    fill: ${(props) => props.theme.primary};
  }
`;

NavigationComponent.propTypes = {
  leftMobile: PropTypes.element,
  leftDesktop: PropTypes.element,
  rightMobile: PropTypes.element,
  rightDesktop: PropTypes.element,
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};

NavigationComponent.defaultProps = {
  leftMobile: null,
  rightMobile: null,
  leftDesktop: null,
  rightDesktop: null,
  pathname: '',
};

export default StyledNavigation;
