import PropTypes from 'prop-types';
import {
  Box, Button, Heading, Text,
} from '@cfacorp/cowponents';
import styled from 'styled-components';
import OverlayModal from './OverlayModal';
import Icon from '../Icon';

const ItemWarningModal = (
  {
    isOpen, message, onConfirm, onClose,
  },
) => (
  <StyledItemWarningModal isOpen={isOpen} close={onClose}>
    <Close onClick={onClose}>
      <Icon>close</Icon>
    </Close>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      m="3em"
    >
      <Icon>warning</Icon>
      <Heading as="h1" fontSize="5" m="1em">
        Would you still like to order it?
      </Heading>
      <Text color="textColor">{message}</Text>
      <Box display="flex" justifyContent="space-around" alignItems="center" width="100%" mt="1em">
        <Button className="btn-no" variant="secondary" onClick={onClose}>No, go back</Button>
        <Button className="btn-yes" onClick={onConfirm}>Yes, add to order</Button>
      </Box>
    </Box>
  </StyledItemWarningModal>
);

/* istanbul ignore next */
export const StyledItemWarningModal = styled(OverlayModal)`
  text-align: center;
  padding: 20px;
  @media (max-width: ${(props) => props.theme.phone}) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      border: 1px solid #ccc;
      background: #fff;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      outline: none;
      font-size: 14px;
      font-family: ${(props) => props.theme.fontFamily};
      max-height: 90vh;
      overflow-y: scroll;
    }
  }
`;

/* istanbul ignore next */
export const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;
  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

ItemWarningModal.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ItemWarningModal.defaultProps = {
  isOpen: false,
  message: '',
  onConfirm: () => {},
  onClose: () => {},
};

export default ItemWarningModal;
