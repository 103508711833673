import { throwError } from 'rxjs';
import ooeConstants from '../constants';
import request from '../util/requestFactory';
import { indexBy } from '../util/utils';

const editExistingOrder = (guestAccessToken, order) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${order.id}/resubmit`;

  return request({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: order,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Edit Existing Order',
    },
  });
};

const processMenu = (res) => {
  const itemGroups = indexBy(res.itemGroups, 'itemGroupId', { first: true });
  return { ...res, itemGroups };
};

const getMenuFromApi = ({ location, type, destination } = {}) => {
  if (location == null || type == null) {
    return {
      execute: () => throwError(new Error('location and type are required')),
    };
  }

  const baseUrl = ooeConstants.URL.ORDERS;
  let url;
  url = `${baseUrl}/locations/3.1/${location}/menu/client/${type}`;
  if (destination) {
    url += `/${destination}`;
  }
  const mapper = processMenu;
  return request({
    url,
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Menu',
    },
  });
};

const getNutrition = () => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/2.0/menu/client/nutrition`;
  return request({
    url,
    mapper: res => res.items,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Nutrition',
    },
  });
};

const getOrderDates = (guestAccessToken, orderId) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/dates`;

  return request({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Order Dates',
    },
  });
};

const getOrderTimes = (guestAccessToken, orderId) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/timeSlots`;
  const mapper = res => res.timeSlots;
  return request({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    mapper,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Get Order Times',
    },
  });
};

const initiateOrder = (guestAccessToken, order) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0`;
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: order,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Initiate Order',
    },
  });
};

const requestPaymentApi = (guestAccessToken, orderId) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}/paymentRequest`;
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Request Payment Api',
    },
  });
};

const updateOrder = (guestAccessToken, order) => {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${order.id}`;

  return request({
    url,
    method: 'PUT',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: order,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Update order',
    },
  });
};

const autocompleteAddressFromApi = (address, guestAccessToken) => {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const validAddress = encodeURIComponent(address);
  const url = `${baseUrl}/locations/2.0/address/autocomplete?address=${validAddress}`;
  return request({
    url,
    method: 'GET',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Autocomplete address',
    },
  });
};

const validateAddressFromApi = (address, guestAccessToken) => {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/2.0/address/validate`;
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: address,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Validate address',
    },
  });
};

const validateDeliveryRange = (address, guestAccessToken) => {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/catering/delivery`;
  return request({
    url,
    method: 'POST',
    auth: {
      type: 'Bearer',
      apiKey: guestAccessToken,
    },
    body: address,
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Validate delivery range',
    },
  });
};

export {
  autocompleteAddressFromApi,
  editExistingOrder,
  getMenuFromApi,
  getNutrition,
  getOrderDates,
  getOrderTimes,
  initiateOrder,
  requestPaymentApi,
  updateOrder,
  validateAddressFromApi,
  validateDeliveryRange,
};
