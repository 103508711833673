import { of } from 'rxjs';
import { find, isEmpty, propEq } from 'ramda';
import { ofType } from 'redux-observable';
import {
  filter,
  map,
  switchMap,
  take,
  debounceTime,
  catchError,
} from 'rxjs/operators';
import {
  actions,
  keys,
  selectApiKey,
  selectPastOrdersLength,
  selectRefreshToken,
  selectTokenType,
  selectVaultedCards,
  types as guestTypes,
} from '../reducers/guest';
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhoneFormForApi,
  selectValidEmail,
  selectValidPhone,
} from '../reducers/form';
import {
  selectDestination,
  types as orderTypes,
} from '../reducers/order';
import {
  selectAccessToken,
  selectLocationNumber,
  selectUserHasAgreedToEula,
  types as userTypes,
} from '../reducers/user';
import ooeConstants from '../constants';
import epicHelper, { epicOptions } from '../util/myEpicHelper';
import { startSession, leaveBreadcrumb } from '../services/bugsnag';
import {
  getGuestSearchResultsFromApi,
  getGuestEmailSearchResultsFromApi,
  getGuestPhoneSearchResultsFromApi,
  authenticateOnGuestBehalf,
  refreshToken,
  getPaymentFromApi,
  lookupPaymentByAccountId,
  initiateGuestSession,
  validateZipFromApi,
  getGuestPastOrders,
  getFavoriteOrders,
  addToFavorites,
  removeFromFavorites,
  updateFavoriteName,
} from '../apis';

export const InitiateGuestSession = (action$, store) => action$
  .pipe(
    ofType(
      guestTypes.CLEAR_GUEST_STATE,
      orderTypes.EXIT_EDIT_ORDER,
      userTypes.REFRESH_TOKEN_SUCCESS,
      userTypes.PROCESS_OKTA_TOKEN_SUCCESS,
      userTypes.MESSAGE_ACKNOWLEDGED_SUCCESS,
      userTypes.ADD_USER_TO_ACKNOWLEDGEMENT_SUCCESS,
    ),
    filter(() => {
      const state = store.value;
      return window.Cypress || selectUserHasAgreedToEula(state);
    }),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);

      startSession();

      return epicHelper(
        initiateGuestSession(accessToken),
        actions.guestSessionSuccess,
        actions.guestSessionFailure,
        epicOptions(store, action$),
      );
    }),
  );

export /* istanbul ignore next */ const RefreshToken = (action$, store) => action$
  .pipe(
    ofType(guestTypes.REFRESH_TOKEN),
    switchMap(() => {
      const state = store.value;
      const guestRefreshToken = selectRefreshToken(state);
      const tokenType = selectTokenType(state);
      let successAction;
      let failureAction;
      if (tokenType === ooeConstants.TOKEN_TYPES.GUEST_TYPE) {
        successAction = actions.guestSessionSuccess;
        failureAction = actions.guestSessionFailure;
      } else {
        successAction = actions.masqueradeSessionSuccess;
        failureAction = actions.masqueradeSessionFailure;
      }

      leaveBreadcrumb('Refresh Token', {
        message: 'Calling refreshToken',
      });

      return refreshToken(guestRefreshToken)
        .execute()
        .pipe(
          map((res) => successAction(res)),
          catchError((err) => of(failureAction(err.toString()))),
        );
    }),
  );

export const AuthenticateOnGuestBehalf = (action$, store) => action$
  .pipe(
    ofType(
      guestTypes.GUEST_SELECTED,
      orderTypes.INITIATE_EDIT_ORDER,
    ),
    filter(({ guest, reorder }) => !isEmpty(guest) && reorder !== true),
    switchMap(({ guest }) => {
      const { cfaId } = guest;
      const state = store.value;
      const accessToken = selectAccessToken(state);

      leaveBreadcrumb('Authenticate On Guest Behalf', {
        message: 'Calling authenticateOnGuestBehalf',
        cfaId,
      });

      return epicHelper(
        authenticateOnGuestBehalf(cfaId, accessToken),
        actions.masqueradeSessionSuccess,
        actions.masqueradeSessionFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetGuestSearchResults = (action$, store) => action$
  .pipe(
    ofType(guestTypes.GUEST_SEARCH_REQUEST),
    debounceTime(500),
    switchMap(() => {
      const state = store.value;
      const locationNumber = selectLocationNumber(state);
      const searchedFirstName = selectFirstName(state) || '';
      const searchedLastName = selectLastName(state) || '';
      const accessToken = selectAccessToken(state);

      if (searchedFirstName.length === 0 && searchedLastName.length === 0) {
        return of(actions.guestSearchSuccess([]));
      }

      const cfaOneOnly = true;

      leaveBreadcrumb('Get Guest Search Results From Api', {
        message: 'Calling getGuestSearchResultsFromApi',
        searchedFirstName,
        searchedLastName,
        locationNumber,
        cfaOneOnly,
      });

      return epicHelper(
        getGuestSearchResultsFromApi(
          searchedFirstName,
          searchedLastName,
          accessToken,
          locationNumber,
          { cfaOneOnly },
        ),
        actions.guestSearchSuccess,
        actions.guestSearchFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetGuestEmailSearchResults = (action$, store) => action$
  .pipe(
    ofType(guestTypes.GUEST_SEARCH_EMAIL_REQUEST),
    debounceTime(500),
    switchMap(() => {
      const state = store.value;
      const searchedEmail = selectEmail(state);
      const isValidEmail = selectValidEmail(state);
      const accessToken = selectAccessToken(state);

      if (!isValidEmail) {
        return of(actions.guestSearchEmailSuccess([]));
      }

      leaveBreadcrumb('Get Guest Email Search Results', {
        message: 'Calling getGuestEmailSearchResultsFromApi',
        searchedEmail,
        isValidEmail,
      });

      return epicHelper(
        getGuestEmailSearchResultsFromApi(searchedEmail, accessToken),
        actions.guestSearchEmailSuccess,
        actions.guestSearchEmailFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetGuestPhoneSearchResults = (action$, store) => action$
  .pipe(
    ofType(guestTypes.GUEST_SEARCH_PHONE_REQUEST),
    debounceTime(500),
    switchMap(() => {
      const state = store.value;
      const searchedPhone = selectPhoneFormForApi(state);
      const isValidPhone = selectValidPhone(state);
      const accessToken = selectAccessToken(state);

      if (!isValidPhone) {
        return of(actions.guestSearchPhoneSuccess([]));
      }

      leaveBreadcrumb('Get Guest Phone Search Results', {
        message: 'Calling getGuestPhoneSearchResultsFromApi',
        searchedPhone,
        isValidPhone,
      });

      return epicHelper(
        getGuestPhoneSearchResultsFromApi(searchedPhone, accessToken),
        actions.guestSearchPhoneSuccess,
        actions.guestSearchPhoneFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetPayment = (action$, store) => action$
  .pipe(
    ofType(guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state);

      leaveBreadcrumb('Get Payment', {
        message: 'Calling getPaymentFromApi',
      });

      return epicHelper(
        getPaymentFromApi(apiKey),
        actions.paymentSuccess,
        actions.paymentFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetOrderPaymentAccount = (action$, store) => action$
  .pipe(
    ofType(orderTypes.INITIATE_EDIT_ORDER),
    // Don't get vaulted cards here if reordering
    filter(({ reorder }) => !reorder),
    switchMap(({ order: { payment }, reorder }) => {
      leaveBreadcrumb('Get Order Payment Account', {
        message: 'Routing payments, vaulted cards',
        reorder,
        payment,
      });

      // If reordering then use the already vaulted cards from state
      /* istanbul ignore if */
      if (reorder === true) {
        const state = store.value;
        const vaultedCards = selectVaultedCards(state);
        return of({ vaultedCards, payment });
      }

      // If a traditional edit order, then wait for vaulted payment to return
      return action$
        .pipe(
          ofType(guestTypes.GUEST_PAYMENT_SUCCESS),
          take(1),
          /* istanbul ignore next */
          map(({ vaultedCards }) => ({ vaultedCards, payment })),
        );
    }),
    /* istanbul ignore next */
    filter(({ payment }) => {
      if (!payment) {
        return false;
      }

      leaveBreadcrumb('Get Order Payment Account Filtering On Payment', {
        message: 'Filtering on payment type of ACCOUNT',
        payment,
      });

      return payment.paymentType === 'ACCOUNT';
    }),
    /* istanbul ignore next */
    switchMap(({ vaultedCards, payment: { accountId } }) => {
      const foundAccount = find(propEq('id', accountId), vaultedCards);
      if (foundAccount) {
        return of(actions.getOrderAccountSuccess(foundAccount));
      }

      const state = store.value;
      const apiKey = selectApiKey(state);

      leaveBreadcrumb('Get Order Payment Account By ID', {
        message: 'Calling lookupPaymentByAccountId',
        accountId,
      });

      return epicHelper(
        lookupPaymentByAccountId(accountId, apiKey),
        actions.getOrderAccountSuccess,
        actions.getOrderAccountFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const ValidateZip = (action$, store) => action$
  .pipe(
    ofType(guestTypes.GUEST_VALIDATE_ZIP_REQUEST),
    switchMap(({ zip, card }) => {
      const state = store.value;
      const apiKey = selectApiKey(state);

      const { accountDisplay, cardType, validated } = card;

      leaveBreadcrumb('Validate Card Zipcode', {
        message: 'Calling validateZipFromApi',
        card: { accountDisplay, cardType, validated },
      });

      return epicHelper(
        validateZipFromApi({ zip, card, apiKey }),
        actions.validateZipSuccess,
        actions.validateZipFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetPastOrders = (action$, store) => action$
  .pipe(
    ofType(
      guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS,
      guestTypes.GET_MORE_PAST_ORDERS,
    ),
    switchMap((action) => {
      const { key } = action;
      const state = store.value;
      const apiKey = selectApiKey(state);
      const pastOrdersLength = selectPastOrdersLength(state);
      const numToLoad = pastOrdersLength + ooeConstants.NUM_ADDTL_PAST_ORDERS_TO_LOAD;
      let successAction = actions.getPastOrdersSuccess;
      let failureAction = actions.getPastOrdersFailure;
      if (key === keys.GET_MORE_PAST_ORDERS) {
        successAction = actions.getMorePastOrdersSuccess;
        failureAction = actions.getMorePastOrdersFailure;
      }

      leaveBreadcrumb('Get Past Orders', {
        message: 'Calling getGuestPastOrders',
        numToLoad,
      });

      return epicHelper(
        getGuestPastOrders(apiKey, numToLoad),
        successAction,
        failureAction,
        epicOptions(store, action$),
      );
    }),
  );

export const GetPastDeliveryAdresses = (action$, store) => action$
  .pipe(
    ofType(guestTypes.GET_PAST_DELIVERY_ADDRESSES),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state);
      const numToLoad = ooeConstants.NUM_OF_PAST_ORDERS_FOR_DELIVERY_ADDRESSES;

      return epicHelper(
        getGuestPastOrders(apiKey, numToLoad),
        actions.getPastDeliveryAddressesSuccess,
        actions.getPastDeliveryAddressesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetFavoriteOrders = (action$, store) => action$
  .pipe(
    ofType(
      guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS,
      guestTypes.ADD_TO_FAVORITES_SUCCESS,
      guestTypes.REMOVE_FROM_FAVORITES_SUCCESS,
      guestTypes.UPDATE_FAVORITE_NAME_SUCCESS,
    ),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state);
      const locationNumber = selectLocationNumber(state);
      const destination = selectDestination(state);

      leaveBreadcrumb('Get Favorite Orders', {
        message: 'Calling getFavoriteOrders',
      });

      return epicHelper(
        getFavoriteOrders(apiKey, locationNumber, destination, true),
        actions.getFavoriteOrdersSuccess,
        actions.getFavoriteOrdersFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const AddToFavorites = (action$, store) => action$
  .pipe(
    ofType(guestTypes.ADD_TO_FAVORITES),
    switchMap(({ orderId, favoriteName }) => {
      const state = store.value;
      const apiKey = selectApiKey(state);

      leaveBreadcrumb('Add To Favorites', {
        message: 'Calling addToFavorites',
        orderId,
        favoriteName,
      });

      return epicHelper(
        addToFavorites(orderId, favoriteName, apiKey),
        actions.addToFavoritesSuccess,
        actions.addToFavoritesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const RemoveFromFavorites = (action$, store) => action$
  .pipe(
    ofType(guestTypes.REMOVE_FROM_FAVORITES),
    switchMap(({ favoriteOrderId }) => {
      const state = store.value;
      const apiKey = selectApiKey(state);

      leaveBreadcrumb('Remove From Favorites', {
        message: 'Calling removeFromFavorites',
        favoriteOrderId,
      });

      return epicHelper(
        removeFromFavorites(favoriteOrderId, apiKey),
        actions.removeFromFavoritesSuccess,
        actions.removeFromFavoritesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const UpdateFavoriteName = (action$, store) => action$
  .pipe(
    ofType(guestTypes.UPDATE_FAVORITE_NAME),
    switchMap(({ favoriteOrderId, favoriteName }) => {
      const state = store.value;
      const apiKey = selectApiKey(state);

      leaveBreadcrumb('Update Favorite Name', {
        message: 'Calling updateFavoriteName',
        favoriteOrderId,
        favoriteName,
      });

      return epicHelper(
        updateFavoriteName(favoriteOrderId, favoriteName, apiKey),
        actions.updateFavoriteNameSuccess,
        actions.updateFavoriteNameFailure,
        epicOptions(store, action$),
      );
    }),
  );

export default [
  AuthenticateOnGuestBehalf,
  InitiateGuestSession,
  GetGuestSearchResults,
  GetGuestEmailSearchResults,
  GetGuestPhoneSearchResults,
  GetPayment,
  GetOrderPaymentAccount,
  ValidateZip,
  RefreshToken,
  GetPastOrders,
  GetFavoriteOrders,
  AddToFavorites,
  RemoveFromFavorites,
  UpdateFavoriteName,
  GetPastDeliveryAdresses,
];
