import ooeConstants from '../constants';
import request from '../util/requestFactory';

const getFeatureFlags = () => {
    const url = `${ooeConstants.URL.CMT_CONFIG}/1.0/feature-flags`;
    return request({
        url,
        method: 'GET',
        bugsnag: {
            errorClass: ooeConstants.BUGSNAG_ERRORCLASS_CMT_CONFIG,
            context: 'Get Feature Flags From Config',
        },
    });
};

export default getFeatureFlags;
