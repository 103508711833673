import { getUnixTime, parseISO } from 'date-fns';
import {
  contains,
  findIndex,
  isEmpty,
  isNil,
  last,
  merge,
  pathOr,
  prop,
  propEq,
  propOr,
  sort,
  toUpper,
  uniqBy,
  values,
} from 'ramda';
import { createSelector } from 'reselect';
import ooeConstants from '../constants';
import {
  guestErrorMessages,
  submitWarningErrorMessages,
} from '../util/customerErrorMessages';
import makeActionCreator from '../util/makeActionCreator';
import { camelToTitleCase } from '../util/utils';
import { favoriteNameMatchesList } from '../util/validate';
import { types as orderTypes } from './order';

export const types = {
  GUEST_SESSION_SUCCESS: '[Guest] Guest Session Success',
  GUEST_SESSION_FAILURE: '[Guest] Guest Session Failure',
  GUEST_SEARCH_REQUEST: '[Guest] Search Request',
  GUEST_SEARCH_SUCCESS: '[Guest] Search Success',
  GUEST_SEARCH_FAILURE: '[Guest] Search Failure',
  GUEST_SEARCH_EMAIL_REQUEST: '[Guest] Search Email Request',
  GUEST_SEARCH_EMAIL_SUCCESS: '[Guest] Search Email Success',
  GUEST_SEARCH_EMAIL_FAILURE: '[Guest] Search Email Failure',
  GUEST_SEARCH_PHONE_REQUEST: '[Guest] Search Phone Request',
  GUEST_SEARCH_PHONE_SUCCESS: '[Guest] Search Phone Success',
  GUEST_SEARCH_PHONE_FAILURE: '[Guest] Search Phone Failure',
  GUEST_SELECTED: '[Guest] Selected',
  MASQUERADE_GUEST_UNSELECTED: '[Guest] Masquerade Guest Unselected',
  GUEST_MASQUERADE_SESSION_SUCCESS: '[Guest] Masquerade Session Success',
  GUEST_MASQUERADE_SESSION_FAILURE: '[Guest] Masquerade Session Failure',
  GUEST_PAYMENT_REQUEST: '[Guest] Payment Request',
  GUEST_PAYMENT_SUCCESS: '[Guest] Payment Success',
  GUEST_PAYMENT_FAILURE: '[Guest] Payment Failure',
  GET_ORDER_ACCOUNT_SUCCESS: '[Guest] Order payment account success',
  GET_ORDER_ACCOUNT_FAILURE: '[Guest] Order payment account failure',
  GUEST_VALIDATE_ZIP_REQUEST: '[Guest] Validate Zip Request',
  GUEST_VALIDATE_ZIP_SUCCESS: '[Guest] Validate Zip Success',
  GUEST_VALIDATE_ZIP_FAILURE: '[Guest] Validate Zip Failure',
  REFRESH_TOKEN: '[Guest] Refresh Token',
  REFRESH_TOKEN_SUCCESS: '[Guest] Refresh Token Success',
  REFRESH_TOKEN_FAILURE: '[Guest] Refresh Token Failure',
  UPDATE_PROFILE_SUCCESS: '[Guest] Update Profile Success',
  UPDATE_PROFILE_FAILURE: '[Guest] Update Profile Failure',
  GET_PAST_ORDERS_SUCCESS: '[Order] Get Past Orders Success',
  GET_PAST_ORDERS_FAILURE: '[Order] Get Past Orders Failure',
  GET_MORE_PAST_ORDERS: '[Guest] Get More Past Orders',
  GET_MORE_PAST_ORDERS_SUCCESS: '[Order] Get More Past Orders Success',
  GET_MORE_PAST_ORDERS_FAILURE: '[Guest] Get More Past Orders Failure',
  GET_FAVORITE_ORDERS_SUCCESS: '[Guest] Get Favorite Orders Success',
  GET_FAVORITE_ORDERS_FAILURE: '[Guest] Get Favorite Orders Failure',
  ADD_TO_FAVORITES: '[Guest] Add to Favorite Orders',
  ADD_TO_FAVORITES_SUCCESS: '[Guest] Add to Favorite Orders Success',
  ADD_TO_FAVORITES_FAILURE: '[Guest] Add to Favorite Orders Failure',
  REMOVE_FROM_FAVORITES: '[Guest] Remove from Favorite Orders',
  REMOVE_FROM_FAVORITES_SUCCESS: '[Guest] Remove fom Favorite Orders Success',
  REMOVE_FROM_FAVORITES_FAILURE: '[Guest] Remove fom Favorite Orders Failure',
  UPDATE_FAVORITE_NAME: '[Guest] Update Favorite Name',
  UPDATE_FAVORITE_NAME_SUCCESS: '[Guest] Update Favorite Name Success',
  UPDATE_FAVORITE_NAME_FAILURE: '[Guest] Update Favorite Name Failure',
  RESET_FAVORITE_ACTION_ERRORS: '[Guest] Reset Favorite Action Errors',
  GET_PAST_DELIVERY_ADDRESSES: '[Guest] Get Past Delivery Addresses',
  GET_PAST_DELIVERY_ADDRESSES_SUCCESS: '[Guest] Get Past Delivery Addresses Success',
  GET_PAST_DELIVERY_ADDRESSES_FAILURE: '[Guest] Get Past Delivery Addresses Failure',
  CLEAR_GUEST_STATE: '[Guest] Clear Guest State',
  CLOSE_EMPTY_PHONE_FIELD_WARNING: '[Guest] Close Warning Modal For Empty CFA One Phone Field',

};

export const keys = {
  GUEST_SESSION: 'guestSession',
  EMAIL_SEARCH: 'emailSearch',
  PHONE_SEARCH: 'phoneSearch',
  NAME_SEARCH: 'nameSearch',
  GET_FAVORITE_ORDERS: 'getFavoriteOrders',
  PAST_ORDERS: 'pastOrders',
  GET_MORE_PAST_ORDERS: 'getMorePastOrders',
  ADD_FAVORITE: 'addToFavorites',
  REMOVE_FAVORITE: 'removeFromFavorites',
  UPDATE_FAVORITE_NAME: 'updateFavoriteName',
  GET_PAST_DELIVERY_ADDRESSES: 'getPastDeliveryAddresses',
};

export const actions = makeActionCreator({
  guestSessionSuccess: (session) => ({
    type: types.GUEST_SESSION_SUCCESS,
    key: keys.GUEST_SESSION,
    session,
  }),
  guestSessionFailure: (error) => ({
    type: types.GUEST_SESSION_FAILURE,
    key: keys.GUEST_SESSION,
    error,
  }),

  guestSearch: () => ({
    type: types.GUEST_SEARCH_REQUEST,
    key: keys.NAME_SEARCH,
  }),
  guestSearchSuccess: (searchResults) => ({
    type: types.GUEST_SEARCH_SUCCESS,
    key: keys.NAME_SEARCH,
    searchResults,
  }),
  guestSearchFailure: (error) => ({
    type: types.GUEST_SEARCH_FAILURE,
    key: keys.NAME_SEARCH,
    error,
  }),
  guestSearchEmail: () => ({
    type: types.GUEST_SEARCH_EMAIL_REQUEST,
    key: keys.EMAIL_SEARCH,
  }),
  guestSearchEmailSuccess: (searchResults) => ({
    type: types.GUEST_SEARCH_EMAIL_SUCCESS,
    key: keys.EMAIL_SEARCH,
    searchResults,
  }),
  guestSearchEmailFailure: (error) => ({
    type: types.GUEST_SEARCH_EMAIL_FAILURE,
    key: keys.EMAIL_SEARCH,
    error,
  }),
  guestSearchPhone: () => ({
    type: types.GUEST_SEARCH_PHONE_REQUEST,
    key: keys.PHONE_SEARCH,
  }),
  guestSearchPhoneSuccess: (searchResults) => ({
    type: types.GUEST_SEARCH_PHONE_SUCCESS,
    key: keys.PHONE_SEARCH,
    searchResults,
  }),
  guestSearchPhoneFailure: (error) => ({
    type: types.GUEST_SEARCH_PHONE_FAILURE,
    key: keys.PHONE_SEARCH,
    error,
  }),

  guestSelected: (guest) => ({
    type: types.GUEST_SELECTED,
    guest,
  }),
  masqueradeGuestUnselected: () => ({ type: types.MASQUERADE_GUEST_UNSELECTED }),
  masqueradeSessionSuccess: (session) => ({
    type: types.GUEST_MASQUERADE_SESSION_SUCCESS,
    session,
  }),
  masqueradeSessionFailure: () => ({
    type: types.GUEST_MASQUERADE_SESSION_FAILURE,
  }),

  paymentSuccess: (vaultedCards) => ({
    type: types.GUEST_PAYMENT_SUCCESS,
    vaultedCards,
  }),
  paymentFailure: (error) => ({
    type: types.GUEST_PAYMENT_FAILURE,
    error,
  }),
  getOrderAccountSuccess: (account) => ({
    type: types.GET_ORDER_ACCOUNT_SUCCESS,
    account,
  }),
  getOrderAccountFailure: (error) => ({
    type: types.GET_ORDER_ACCOUNT_FAILURE,
    error,
  }),

  validateZip: (zip, card) => ({
    type: types.GUEST_VALIDATE_ZIP_REQUEST,
    zip,
    card,
  }),
  validateZipSuccess: (card) => ({
    type: types.GUEST_VALIDATE_ZIP_SUCCESS,
    card,
  }),
  validateZipFailure: (error) => ({
    type: types.GUEST_VALIDATE_ZIP_FAILURE,
    error,
  }),

  refreshToken: () => ({
    type: types.REFRESH_TOKEN,
  }),
  refreshTokenSuccess: (newToken) => ({
    type: types.REFRESH_TOKEN_SUCCESS,
    newToken,
  }),

  getPastOrdersSuccess: (pastOrders) => ({
    type: types.GET_PAST_ORDERS_SUCCESS,
    key: keys.PAST_ORDERS,
    pastOrders,
  }),
  getPastOrdersFailure: (error) => ({
    type: types.GET_PAST_ORDERS_FAILURE,
    key: keys.PAST_ORDERS,
    error,
  }),
  getMorePastOrders: () => ({ type: types.GET_MORE_PAST_ORDERS, key: keys.GET_MORE_PAST_ORDERS }),
  getMorePastOrdersSuccess: (pastOrders) => ({
    type: types.GET_MORE_PAST_ORDERS_SUCCESS,
    key: keys.GET_MORE_PAST_ORDERS,
    pastOrders,
  }),
  getMorePastOrdersFailure: (error) => ({
    type: types.GET_MORE_PAST_ORDERS_FAILURE,
    key: keys.GET_MORE_PAST_ORDERS,
    error,
  }),
  getFavoriteOrdersSuccess: (favoriteOrders) => ({
    type: types.GET_FAVORITE_ORDERS_SUCCESS,
    favoriteOrders,
  }),
  getFavoriteOrdersFailure: (error) => ({
    type: types.GET_FAVORITE_ORDERS_FAILURE,
    key: keys.GET_FAVORITE_ORDERS,
    error,
  }),
  addToFavorites: (orderId, favoriteName) => ({
    type: types.ADD_TO_FAVORITES,
    key: keys.ADD_FAVORITE,
    orderId,
    favoriteName,
  }),
  addToFavoritesSuccess: (favoriteOrder) => ({
    type: types.ADD_TO_FAVORITES_SUCCESS,
    key: keys.ADD_FAVORITE,
    favoriteOrder,
  }),
  addToFavoritesFailure: (error) => ({
    type: types.ADD_TO_FAVORITES_FAILURE,
    key: keys.ADD_FAVORITE,
    error,
  }),
  removeFromFavorites: (favoriteOrderId) => ({
    type: types.REMOVE_FROM_FAVORITES,
    key: keys.REMOVE_FAVORITE,
    favoriteOrderId,
  }),
  removeFromFavoritesSuccess: () => ({
    type: types.REMOVE_FROM_FAVORITES_SUCCESS,
    key: keys.REMOVE_FAVORITE,
  }),
  removeFromFavoritesFailure: (error) => ({
    type: types.REMOVE_FROM_FAVORITES_FAILURE,
    key: keys.REMOVE_FAVORITE,
    error,
  }),
  updateFavoriteName: (favoriteOrderId, favoriteName) => ({
    type: types.UPDATE_FAVORITE_NAME,
    key: keys.UPDATE_FAVORITE_NAME,
    favoriteOrderId,
    favoriteName,
  }),
  updateFavoriteNameSuccess: (favoriteOrder) => ({
    type: types.UPDATE_FAVORITE_NAME_SUCCESS,
    key: keys.UPDATE_FAVORITE_NAME,
    favoriteOrder,
  }),
  updateFavoriteNameFailure: (error) => ({
    type: types.UPDATE_FAVORITE_NAME_FAILURE,
    key: keys.UPDATE_FAVORITE_NAME,
    error,
  }),
  resetFavoriteActionErrors: () => ({ type: types.RESET_FAVORITE_ACTION_ERRORS }),
  getPastDeliveryAddresses: () => ({
    type: types.GET_PAST_DELIVERY_ADDRESSES,
    key: keys.GET_PAST_DELIVERY_ADDRESSES,
  }),
  getPastDeliveryAddressesSuccess: (response) => ({
    type: types.GET_PAST_DELIVERY_ADDRESSES_SUCCESS,
    key: keys.GET_PAST_DELIVERY_ADDRESSES,
    response,
  }),
  getPastDeliveryAddressesFailure: (error) => ({
    type: types.GET_PAST_DELIVERY_ADDRESSES_FAILURE,
    key: keys.GET_PAST_DELIVERY_ADDRESSES,
    error,
  }),
  closeEmptyCfaPhoneFieldWarning: () => ({ type: types.CLOSE_EMPTY_PHONE_FIELD_WARNING }),
  clearGuestState: () => ({ type: types.CLEAR_GUEST_STATE }),
});

export const initialState = {
  error: {},
  loading: {},
  searchResults: {
    [keys.NAME_SEARCH]: [],
    [keys.EMAIL_SEARCH]: [],
    [keys.PHONE_SEARCH]: [],
  },
  selectedCfaOneGuest: {},
  vaultedCards: [],
  selectedCard: {},
  guestSession: {},
  masqueradeSession: {},
  favoriteOrders: [],
  pastDeliveryAddresses: [],
  emptyCfaPhoneField: false,
};
export function isAllowedCard(card) {
  const cardType = propOr('', ['cardType'], card);
  if (isNil(cardType)) {
    return false;
  }
  return contains(toUpper(cardType), ooeConstants.allowedCardTypes);
}
export const filterAllowedCards = (cards = []) => cards.filter(isAllowedCard);
export function generateId(card) {
  return `${card.expirationMonth}-${card.expirationYear}-${card.accountDisplay}-${card.cardType}`;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GUEST_SEARCH_REQUEST:
    case types.GUEST_SEARCH_EMAIL_REQUEST:
    case types.GUEST_SEARCH_PHONE_REQUEST: {
      const { key } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: true,
        },
      };
    }

    case types.GUEST_SESSION_SUCCESS: {
      const { key, session } = action;
      return {
        ...state,
        guestSession: { ...state.guestSession, ...session },
        loading: {
          ...state.loading,
          [key]: false,
        },
        error: {
          ...state.error,
          [key]: null,
        },
      };
    }

    case types.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        guestSession: {
          ...state.guestSession,
          accessToken: action.newToken,
        },
      };
    }

    case types.GUEST_SEARCH_SUCCESS:
    case types.GUEST_SEARCH_EMAIL_SUCCESS:
    case types.GUEST_SEARCH_PHONE_SUCCESS: {
      const { searchResults, key } = action;
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [key]: searchResults,
        },
        error: {
          ...state.error,
          [key]: null,
        },
        loading: {
          ...state.loading,
          [key]: false,
        },
      };
    }

    case types.GUEST_SESSION_FAILURE: {
      const { error, key } = action;
      return {
        ...state,
        error: {
          ...state.error,
          [key]: error === 'Error' ? 'Error: Guest session failure' : error,
        },
        loading: {
          ...state.loading,
          [key]: false,
        },
      };
    }

    case types.GUEST_SEARCH_FAILURE:
    case types.GUEST_SEARCH_EMAIL_FAILURE:
    case types.GUEST_SEARCH_PHONE_FAILURE: {
      const { error, key } = action;
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [key]: [],
        },
        error: {
          ...state.error,
          [key]: error === 'Error' ? `${error}: ${camelToTitleCase(key)}` : error,
        },
        loading: {
          ...state.loading,
          [key]: false,
        },
      };
    }

    case types.GUEST_SELECTED: {
      const { guest } = action;
      return {
        ...state,
        selectedCfaOneGuest: {
          ...guest,
          originalPhone: guest.phone,
        },
        loading: { guestSession: true, pastOrders: true, getFavoriteOrders: true },
        pastOrders: [],
        favoriteOrders: [],
        pastDeliveryAddresses: [],
        noPastDeliveryAddresses: false,
        emptyCfaPhoneField: guest?.phone === '',
      };
    }

    case types.MASQUERADE_GUEST_UNSELECTED: {
      return {
        ...state,
        selectedCfaOneGuest: {},
        searchResults: {
          [keys.EMAIL_SEARCH]: [],
          [keys.NAME_SEARCH]: [],
          [keys.PHONE_SEARCH]: [],
        },
        masqueradeSession: {},
        vaultedCards: [],
        selectedCard: {},
        pastOrders: [],
        noMorePastOrdersToLoad: false,
        favoriteOrders: [],
        pastDeliveryAddresses: [],
        noPastDeliveryAddresses: false,
        emptyCfaPhoneField: false,
      };
    }

    case types.GUEST_MASQUERADE_SESSION_SUCCESS: {
      return {
        ...state,
        masqueradeSession: { ...action.session },
        vaultedCards: [],
        selectedCard: {},
        error: {
          ...state.error,
          guestSession: null,
        },
        loading: {
          ...state.loading,
          vaultedCards: true,
          guestSession: false,
        },
      };
    }

    case types.GUEST_MASQUERADE_SESSION_FAILURE: {
      return {
        ...state,
        selectedCfaOneGuest: {},
        error: {
          ...state.error,
          guestSession: guestErrorMessages.MASQUERADE_FAILED,
        },
        loading: {
          ...state.loading,
          guestSession: false,
          pastOrders: false,
          getFavoriteOrders: false,
        },
      };
    }

    case types.GUEST_PAYMENT_SUCCESS: {
      const { vaultedCards } = action;
      let vaultedCardsWithId = [];
      if (vaultedCards) {
        const allowedCards = filterAllowedCards(vaultedCards);
        vaultedCardsWithId = allowedCards.map((card) => ({
          ...card,
          generatedId: generateId(card),
          validated: false,
        }));
      }
      return {
        ...state,
        vaultedCards: vaultedCardsWithId,
        error: {
          ...state.error,
          vaultedCards: null,
        },
        loading: {
          ...state.loading,
          vaultedCards: false,
        },
      };
    }

    case types.GUEST_PAYMENT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        vaultedCards: 'error',
        error: {
          ...state.error,
          vaultedCards: error,
        },
        loading: {
          ...state.loading,
          vaultedCards: false,
        },
      };
    }

    case types.GET_ORDER_ACCOUNT_SUCCESS: {
      const { account } = action;
      if (!isAllowedCard(account)) {
        return state;
      }
      const generatedId = generateId(account);
      const selectedCard = { ...account, generatedId, validated: true };
      const existingCards = [...propOr([], 'vaultedCards', state)];
      const foundCardIndex = findIndex(propEq('generatedId', generatedId), existingCards);

      if (foundCardIndex >= 0) {
        existingCards[foundCardIndex] = selectedCard;
      } else {
        existingCards.push(selectedCard);
      }

      return {
        ...state,
        vaultedCards: existingCards,
        selectedCard,
      };
    }

    case types.GET_ORDER_ACCOUNT_FAILURE: {
      return state;
    }

    case types.GUEST_CLEAR_VAULTED_CARDS: {
      return {
        ...state,
        vaultedCards: [],
        selectedCard: {},
        selectedCfaOneGuest: {},
      };
    }

    case types.GUEST_VALIDATE_ZIP_REQUEST: {
      return {
        ...state,
        selectedCard: {},
        loading: {
          ...state.loading,
          validateZip: true,
        },
      };
    }

    case types.GUEST_VALIDATE_ZIP_SUCCESS: {
      const { card } = action;
      const selectedCard = card[0];
      // If no cards are returned throw an error
      if (card.length === 0) {
        return {
          ...state,
          selectedCard: 'error',
          error: {
            ...state.error,
            validateZip: 'Card ZIP code is invalid',
          },
          loading: {
            ...state.loading,
            validateZip: false,
          },
        };
      }
      // If card has already been validated set it as the selected card
      if (selectedCard.validated) {
        return {
          ...state,
          selectedCard,
        };
      }
      // All other cases generate an ID, set validated to true, select card and update vaulted card
      const generatedId = generateId(selectedCard);
      const vaultedCardsWithValidation = state.vaultedCards.map((vaultedCard) => {
        if (vaultedCard.generatedId === generatedId) {
          return { ...selectedCard, generatedId, validated: true };
        }
        return vaultedCard;
      });
      return {
        ...state,
        selectedCard: { ...selectedCard, generatedId, validated: true },
        vaultedCards: vaultedCardsWithValidation,
        error: {
          ...state.error,
          validateZip: null,
        },
        loading: {
          ...state.loading,
          validateZip: false,
        },
      };
    }

    case types.GUEST_VALIDATE_ZIP_FAILURE: {
      const { error } = action;
      return {
        ...state,
        selectedCard: 'error',
        error: {
          ...state.error,
          validateZip: error,
        },
        loading: {
          ...state.loading,
          validateZip: false,
        },
      };
    }

    case orderTypes.INITIATE_EDIT_ORDER: {
      const { guest, reorder } = action;
      if (reorder) {
        return state;
      }
      const mappedGuest = {
        ...guest,
        firstName: propOr('', 'first', guest),
        lastName: propOr('', 'last', guest),
        first: undefined,
        last: undefined,
      };
      return {
        ...state,
        selectedCfaOneGuest: mappedGuest,
        searchResults: {
          [keys.NAME_SEARCH]: [],
          [keys.EMAIL_SEARCH]: [],
          [keys.PHONE_SEARCH]: [],
        },
        vaultedCards: [],
        selectedCard: {},
      };
    }

    case types.CLEAR_GUEST_STATE:
    case orderTypes.EXIT_EDIT_ORDER: {
      return initialState;
    }

    case types.GET_PAST_ORDERS_SUCCESS:
    case types.GET_MORE_PAST_ORDERS_SUCCESS: {
      const { pastOrders, type, key } = action;
      const isNotInitialLoad = (type === types.GET_MORE_PAST_ORDERS_SUCCESS);
      const noMorePastOrdersToLoad = isNotInitialLoad
        && (((pastOrders.length % 3) !== 0) || (state.pastOrders.length === pastOrders.length));
      return {
        ...state,
        pastOrders,
        noMorePastOrdersToLoad,
        loading: {
          ...state.loading,
          [key]: false,
        },
        error: {
          ...state.error,
          [key]: null,
        },
      };
    }

    case types.GET_FAVORITE_ORDERS_SUCCESS: {
      const { favoriteOrders } = action;
      return {
        ...state,
        favoriteOrders,
        loading: {
          ...state.loading,
          getFavoriteOrders: false,
        },
        error: {
          ...state.error,
          getFavoriteOrders: null,
        },
      };
    }

    case types.GET_MORE_PAST_ORDERS:
    case types.ADD_TO_FAVORITES:
    case types.REMOVE_FROM_FAVORITES:
    case types.UPDATE_FAVORITE_NAME: {
      const { key } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: true,
        },
        error: {
          ...state.error,
          [key]: null,
        },
      };
    }

    case types.ADD_TO_FAVORITES_SUCCESS:
    case types.REMOVE_FROM_FAVORITES_SUCCESS:
    case types.UPDATE_FAVORITE_NAME_SUCCESS: {
      const { key } = action;
      return {
        ...state,
        favoriteActionSuccessful: true,
        loading: {
          ...state.loading,
          [key]: false,
        },
        error: {
          ...state.error,
          [key]: null,
        },
      };
    }

    // interim solution to not treat favorites order failures as errors
    // if we do not do that the user can never see past orders which is worse
    // as of this writing the API team is working on a fix after which
    // we can revisit the proper approach here
    case types.GET_FAVORITE_ORDERS_FAILURE: {
      const { key } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: false,
        },
        error: {
          ...state.error,
          [key]: null,
        },
      };
    }

    case types.GET_PAST_DELIVERY_ADDRESSES: {
      const { key } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: true,
        },
        error: {
          ...state.error,
          [key]: null,
        },
      };
    }

    case types.GET_PAST_DELIVERY_ADDRESSES_SUCCESS: {
      const { key, response } = action;
      const pastDeliveryAddresses = response.map(element => (element.deliveryAddress)).filter(address => address != null).filter(
        (value, index, self) => index === self.findIndex(
          (i) => i.addressLine1 === value.addressLine1 && i.city === value.city && i.state === value.state && i.zip === value.zip,
        ),
      );

      if (pastDeliveryAddresses.length < 1) {
        return {
          ...state,
          loading: {
            ...state.loading,
            [key]: false,
          },
          noPastDeliveryAddresses: true,
        };
      }
      if (pastDeliveryAddresses.length >= 1) {
        return {
          ...state,
          loading: {
            ...state.loading,
            [key]: false,
          },
          pastDeliveryAddresses,
        };
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: false,
        },
      };
    }

    // case types.GET_FAVORITE_ORDERS_FAILURE:
    case types.GET_PAST_ORDERS_FAILURE:
    case types.GET_MORE_PAST_ORDERS_FAILURE:
    case types.ADD_TO_FAVORITES_FAILURE:
    case types.REMOVE_FROM_FAVORITES_FAILURE:
    case types.UPDATE_FAVORITE_NAME_FAILURE:
    case types.GET_PAST_DELIVERY_ADDRESSES_FAILURE: {
      const { error, key } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: false,
        },
        error: {
          ...state.error,
          [key]: error,
        },
      };
    }

    case '@@redux-form/CHANGE': {
      if (
        action.meta.form === 'paymentMethod'
        && action.meta.field === 'selectedMethod'
      ) {
        return {
          ...state,
          error: {
            ...state.error,
            validateZip: null,
          },
        };
      }
      return state;
    }

    case types.CLOSE_EMPTY_PHONE_FIELD_WARNING: {
      return {
        ...state,
        emptyCfaPhoneField: false,
      };
    }

    case types.RESET_FAVORITE_ACTION_ERRORS: {
      return {
        ...state,
        favoriteActionSuccessful: false,
        error: {
          ...state.error,
          removeFromFavorites: null,
          addToFavorites: null,
          updateFavoriteName: null,
        },
      };
    }

    default:
      return state;
  }
};

export const selectGuest = (state) => state.guest;
export const selectSearchResults = createSelector(selectGuest, ({ searchResults }) => (
  uniqBy(prop('cfaId'), [
    ...searchResults[keys.EMAIL_SEARCH],
    ...searchResults[keys.PHONE_SEARCH],
    ...searchResults[keys.NAME_SEARCH],
  ])
));

export const selectApiKey = createSelector(
  selectGuest,
  (guest) => {
    const { masqueradeSession, guestSession } = guest;
    if (isEmpty(masqueradeSession)) {
      return guestSession.accessToken;
    }
    return masqueradeSession.accessToken;
  },
);

export const selectTokenType = createSelector(
  selectGuest,
  (guest) => {
    const { masqueradeSession } = guest;
    if (isEmpty(masqueradeSession)) {
      return ooeConstants.TOKEN_TYPES.GUEST_TYPE;
    }
    return ooeConstants.TOKEN_TYPES.MASQUERADE_TYPE;
  },
);

export const selectRefreshToken = createSelector(
  selectGuest,
  (guest) => {
    const { masqueradeSession, guestSession } = guest;
    if (isEmpty(masqueradeSession)) {
      return guestSession.refreshToken;
    }
    return masqueradeSession.refreshToken;
  },
);

export const selectGuestTokenExpiryMs = createSelector(
  selectGuest,
  (guest) => {
    const { masqueradeSession, guestSession } = guest;
    if (isEmpty(masqueradeSession)) {
      return guestSession.expiryTimeMs;
    }
    return masqueradeSession.expiryTimeMs;
  },
);

export const selectVaultedCards = createSelector(
  selectGuest,
  (guest) => guest.vaultedCards,
);
export const selectCardsLoading = createSelector(
  selectGuest,
  (guest) => guest.loading.vaultedCards,
);
export const selectZipLoading = createSelector(selectGuest, (guest) => guest.loading.validateZip);
export const selectCardSelected = createSelector(selectGuest, (guest) => guest.selectedCard || {});
export const selectPayment = createSelector(selectCardSelected, (card) => ({
  accountId: card.id,
  paymentType: 'ACCOUNT',
}));
export const selectCfaOneGuestCFAId = createSelector(
  selectGuest,
  (guest) => guest.selectedCfaOneGuest.cfaId || '',
);
export const selectCfaOneGuestPhone = createSelector(
  selectGuest,
  (guest) => guest.selectedCfaOneGuest.phone || '',
);
export const selectCfaOneGuestOriginalPhone = createSelector(
  selectGuest,
  (guest) => guest.selectedCfaOneGuest.originalPhone || '',
);
export const selectCfaOneGuestEmail = createSelector(
  selectGuest,
  (guest) => guest.selectedCfaOneGuest.email || '',
);

export const selectEmptyCfaOneGuestPhone = createSelector(
  selectGuest,
  (guest) => guest.emptyCfaPhoneField,
);

export const selectSearchIsLoading = createSelector(
  selectGuest,
  (guest) => guest.loading.emailSearch || guest.loading.phoneSearch || guest.loading.nameSearch,
);
export const selectIsCfaOneGuestSelected = createSelector(
  selectGuest,
  (guest) => !!guest.selectedCfaOneGuest.cfaId,
);
export const selectCfaOneGuestId = createSelector(
  selectGuest,
  (guest) => guest.selectedCfaOneGuest.cfaId,
);
export const selectCfaOneGuestName = createSelector(
  selectGuest,
  (guest) => guest.selectedCfaOneGuest.firstName,
);
export const selectPastOrders = createSelector(
  selectGuest,
  (guest) => {
    const orders = pathOr([], ['pastOrders'], guest);
    const compare = (a, b) => (getUnixTime(parseISO(b.promiseDateTime)) - getUnixTime(parseISO(a.promiseDateTime)));
    return sort(compare, orders);
  },
);
export const selectPastOrdersLength = createSelector(
  selectPastOrders,
  (orders) => orders.length,
);
export const selectNoMorePastOrdersToLoad = createSelector(
  selectGuest,
  (guest) => guest.noMorePastOrdersToLoad,
);
export const selectPastAndFavoritesLoading = createSelector(
  selectGuest,
  (guest) => guest.loading.pastOrders || guest.loading.getFavoriteOrders,
);
export const selectLoadingMore = createSelector(
  selectGuest,
  (guest) => guest.loading.getMorePastOrders,
);
export const selectFavoriteOrders = createSelector(
  selectGuest,
  (guest) => {
    const favorites = pathOr([], ['favoriteOrders'], guest);
    const flattenedFavorites = favorites.map((favorite) => merge(favorite, pathOr({}, ['orderDetail'], favorite)));
    return flattenedFavorites.filter((favorite) => favorite.orderType === 'catering');
  },
);
export const selectPastAndFavoriteOrders = createSelector(
  selectPastOrders,
  selectFavoriteOrders,
  (past, favorite) => [...favorite, ...past],
);
export const selectPastOrFavoriteError = createSelector(
  selectGuest,
  (guest) => !!(
    guest.error.pastOrders
    || guest.error.getFavoriteOrders
    || guest.error.getMorePastOrders
  ),
);
export const selectGuestErrors = createSelector(
  selectGuest,
  (guest) => {
    if (guest) {
      return values(guest.error).filter((error) => Boolean(error));
    }
    return [];
  },
);
export const selectFavoritesLoading = createSelector(
  selectGuest,
  (guest) => (
    guest.loading.addToFavorites
    || guest.loading.removeFromFavorites
    || guest.loading.updateFavoriteName
  ),
);
export const selectFavoritesError = createSelector(
  selectGuest,
  (guest) => (
    guest.error.addToFavorites
    || guest.error.removeFromFavorites
    || guest.error.updateFavoriteName
  ),
);
export const selectFavoriteActionSuccessful = createSelector(
  selectGuest,
  (guest) => guest.favoriteActionSuccessful,
);
export const selectCfaGuestSubmitWarning = createSelector(
  selectSearchResults,
  selectIsCfaOneGuestSelected,
  (searchResults, guestSelected) => {
    if (searchResults.length > 0 && !guestSelected) {
      return {
        type: 'warning',
        message: submitWarningErrorMessages.CFA_GUEST,
      };
    }
    return {};
  },
);
export const selectCurrentFavoriteNames = createSelector(
  selectFavoriteOrders,
  (favoriteOrders) => favoriteOrders.map((order) => (prop('name', order))),
);
export const selectFavoriteSuggestedName = createSelector(
  selectCurrentFavoriteNames,
  selectCfaOneGuestName,
  (favoriteNames, firstName) => {
    const suggestedName = `${firstName}'s Catering Order`;
    const nameMatches = favoriteNameMatchesList(favoriteNames, suggestedName);
    // If there are matches, find the number and increment by 1
    if (nameMatches.length) {
      const newNumber = Number(last(nameMatches).match(ooeConstants.FIND_NUMBER_REGEX)) + 1;
      // If the suggested number is 1, start at 2 (per requirements)
      if (newNumber === 1) {
        return `${suggestedName} 2`;
      }
      return `${suggestedName} ${newNumber}`;
    }
    if (!firstName) return '';
    // If no matches are found, return original suggested name
    return suggestedName;
  },
);

export const selectPastDeliveryAddresses = createSelector(
  selectGuest,
  (guest) => guest?.pastDeliveryAddresses,
);

export const selectPastDeliveryAddressesLoading = createSelector(
  selectGuest,
  (guest) => guest?.loading?.getPastDeliveryAddresses,
);

export const selectNoPastDeliveryAddresses = createSelector(
  selectGuest,
  (guest) => guest?.noPastDeliveryAddresses,
);
