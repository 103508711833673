import { path, pick } from 'ramda';
import {
  selectLocationContactDetails,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
} from '../reducers/user';

import { selectCart } from '../reducers/cart';
import { selectMenu } from '../reducers/menu';
import { selectDashboard } from '../reducers/dashboard';
import {
  selectCfaId,
  selectDetailsFormValues,
  selectEventDetailsValid,
  selectDetailsFormSyncErrors,
  selectGuestFormSyncErrors,
  selectFormSyncErrors,
  selectFormSubmitWarning,
  selectPaymentSubmitWarning,
  selectPayWithVaultedCard,
  selectNewPaymentMethod,
  selectTimeShouldClear,
  selectDateIsToday,
  selectDate,
  selectTime,
  selectValidEmail,
  selectValidPhone,
  selectGuestDetailsValid,
  selectPaymentValid,
} from '../reducers/form';
import { maskEmail } from './utils';

const UNAVAILABLE = 'n/a';

function getUserData(state) {
  const { user } = state;
  const userData = pick([
    'selectedLocation',
    'error',
    'loading',
    'shouldBypassBusinessRules',
    'cfa_aud',
    'cfa_guid',
    'cfa_perms',
    'email',
    'accessToken',
    'refreshToken',
    'cmtEula',
  ], user);

  userData.email = maskEmail(userData.email);
  userData.accessToken = userData.accessToken?.slice?.(-6) ?? UNAVAILABLE;
  userData.refreshToken = userData.refreshToken?.slice?.(-6) ?? UNAVAILABLE;
  userData.locationContactDetails = selectLocationContactDetails(state) ?? UNAVAILABLE;
  userData.locationAcceptsDelivery = selectLocationAcceptsDelivery(state) ?? UNAVAILABLE;
  userData.locationAcceptsPickup = selectLocationAcceptsPickup(state) ?? UNAVAILABLE;

  return userData;
}

function getOrderData(state) {
  const { order } = state;
  const orderData = pick([
    'id',
    'editOrderId',
    'reorder',
    'error',
    'loading',
    'status',
    'orderType',
    'reorder',
    'destination',
    'taxAmount',
    'subTotalAmount',
    'promiseDateTime',
    'companyName',
  ], order);
  return orderData;
}

function getGuestData(state) {
  const { guest } = state;

  const guestData = {
    cfaOneId: selectCfaId(state),
    email: maskEmail(path(['selectedCfaOneGuest', 'email'], guest)) ?? UNAVAILABLE,
    error: guest.error,
    guestSession: {
      accessTokenSuffix: state.guest?.guestSession?.accessToken?.slice(-6) ?? UNAVAILABLE,
      refreshTokenSuffix: state.guest?.guestSession?.refreshToken?.slice(-6) ?? UNAVAILABLE,
      expiresIn: state.guest?.guestSession?.expiresIn,
    },
    masqueradeSession: {
      accessTokenSuffix: state.guest?.masqueradeSession?.accessToken?.slice(-6) ?? UNAVAILABLE,
      refreshTokenSuffix: state.guest?.masqueradeSession?.refreshToken?.slice(-6) ?? UNAVAILABLE,
      expiresIn: state.guest?.masqueradeSession?.expiresIn,
    },
    vaultedCardsCount: guest.vaultedCards?.length,
    selectedCard: {
      type: guest.selectedCard?.type,
      cardTtype: guest.selectedCard?.cardType,
      account: guest.selectedCard?.accountDisplay,
      validated: guest.selectedCard?.validated,
      countryCode: guest.selectedCard?.countryCode,
    },
  };
  return guestData;
}

function getCartData(state) {
  return selectCart(state);
}

function getDashboardData(state) {
  const dashboard = selectDashboard(state);
  return {
    loading: dashboard.loading,
    error: dashboard.error,
  };
}

function getMenuData(state) {
  const menu = selectMenu(state);
  return {
    loading: menu.isLoading,
    error: menu.error,
  };
}

const getFormData = (state) => {
  try {
    return {
      cfaId: selectCfaId(state),
      general: {
        formSyncErrors: selectFormSyncErrors(state),
        formSubmitWarning: selectFormSubmitWarning(state),
      },
      eventDetails: {
        eventDetailsValid: selectEventDetailsValid(state),
        detailsFormValues: selectDetailsFormValues(state),
        timeShouldClear: selectTimeShouldClear(state),
        dateIsToday: selectDateIsToday(state),
        date: selectDate(state),
        time: selectTime(state),
        detailsFormSyncErrors: selectDetailsFormSyncErrors(state),
      },
      guestDetails: {
        guestDetailsValid: selectGuestDetailsValid(state),
        validEmail: selectValidEmail(state),
        validPhone: selectValidPhone(state),
        guestFormSyncErrors: selectGuestFormSyncErrors(state),
      },
      payment: {
        paymentSubmitWarning: selectPaymentSubmitWarning(state),
        payWithVaultedCard: selectPayWithVaultedCard(state),
        newPaymentMethod: selectNewPaymentMethod(state),
        paymentValid: selectPaymentValid(state),
        paymentMethod: state.form?.paymentMethod?.values?.paymentMethod,
        selectedMethod: state.form?.paymentMethod?.values?.selectedMethod,
        syncErrors: state.form?.syncErrors,
      },
    };
  } catch (error) {
    return {
      message: 'Unable to get form data',
      error,
    };
  }
};

export default function getBugSnagStateData(store) {
  try {
    const state = store.getState();
    return {
      guest: getGuestData(state),
      user: getUserData(state),
      cart: getCartData(state),
      order: getOrderData(state),
      menu: getMenuData(state),
      dashboard: getDashboardData(state),
      formData: getFormData(state),
    };
  } catch (error) {
    return {
      message: 'Unable to get session metadata',
      error,
    };
  }
}
