import PropTypes from 'prop-types';
import { Flex, Box } from '@cfacorp/cowponents';
import { formatModifier, formatModifierPrice } from '../../util/format';

function Modifiers({ item, side, dessert }) {
  const { modifiers, quantity } = item;

  const renderComboItem = (comboItem) => (
    comboItem && comboItem.name
      && (
      <Flex alignItems="center" lineHeight="25px" key={comboItem.tag}>
        <Box width="50px" textAlign="center">{comboItem.displayQuantity}</Box>
        <Box width="46%" m="0 20px">{comboItem.name}</Box>
        <Box width="30%">{formatModifierPrice(comboItem.price)}</Box>
      </Flex>
      )
  );

  return (
    <div>
      { modifiers && modifiers.map((mod) => (
        <Flex alignItems="center" lineHeight="25px" key={mod.tag}>
          <Box width="50px" textAlign="center">{mod.displayQuantity}</Box>
          <Box width="46%" m="0 20px">{formatModifier(mod, quantity)}</Box>
          <Box width="30%">{formatModifierPrice(mod.price)}</Box>
        </Flex>
      )) }
      {renderComboItem(side)}
      {renderComboItem(dessert)}
      <Box color="primary" ml="70px" lineHeight="25px">{item.specialInstructions || ''}</Box>
    </div>
  );
}

Modifiers.propTypes = {
  item: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])).isRequired,
  side: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
  dessert: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
};

Modifiers.defaultProps = {
  side: {},
  dessert: {},
};

export default Modifiers;
