import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Flex } from '@cfacorp/cowponents';
import constants from '../../constants';
import { formatPrice } from '../../util/format';

function Totals({
  subTotal,
  taxAndTotal,
  isLoading,
  className,
  deliveryTip,
  deliveryTipIsDisplayed,
  deliveryTipLoading,
  promoFreeActive,
  taxAmount,
}) {
  //This will prevent showing negative taxes passed from data range api call
  const isNegativeTax = taxAmount.includes('$-');
  return (
    <StyledTotals className={className}>
      {((subTotal !== constants.ZERO_DOLLARS) || promoFreeActive)
      && (
        <>
          <Flex>
            <Box textAlign="right" fontWeight="bold" width={3 / 4}>Subtotal</Box>
            {isLoading
              ? <Box textAlign="right" color="#e5e0e0" width={1 / 4}>Calculating</Box>
              : <Box textAlign="right" data-cy="subtotal" width={1 / 4}>{subTotal}</Box>}
          </Flex>
            {deliveryTipIsDisplayed && (
              <Flex>
                <Box textAlign="right" fontWeight="bold" width={3 / 4}>Tip Amount</Box>
                {isLoading || deliveryTipLoading
                  ? <Box textAlign="right" color="#e5e0e0" width={1 / 4}>Calculating</Box>
                  : <Box textAlign="right" id="tax-and-total" width={1 / 4}>{formatPrice(deliveryTip?.tipAmount)}</Box>}
              </Flex>
            )}
          {taxAmount !== constants.ZERO_DOLLARS && !isNegativeTax && (
            <Flex>
              <Box textAlign="right" fontWeight="bold" className="taxAmount" width={3 / 4}>Tax</Box>
              {isLoading || deliveryTipLoading
                ? <Box textAlign="right" color="#e5e0e0" width={1 / 4}>Calculating</Box>
                : <Box textAlign="right" id="tax-and-total" width={1 / 4}>{taxAmount}</Box>}
            </Flex>
          )}
          <Flex>
            <Box textAlign="right" fontWeight="bold" className="taxAndTotal" width={3 / 4}>Tax & Total</Box>
            {isLoading || deliveryTipLoading
              ? <Box textAlign="right" color="#e5e0e0" width={1 / 4}>Calculating</Box>
              : <Box textAlign="right" id="tax-and-total" width={1 / 4}>{taxAndTotal}</Box>}
          </Flex>
        </>
      ) }
    </StyledTotals>
  );
}

const StyledTotals = styled.div`
  font: ${(props) => props.theme.regularTextFont};
  color: ${(props) => props.theme.text};
  clear: both;
  padding-top: 1px;
  line-height: 25px;

  & .all-totals {
    padding-top: 1px;
  }
`;

Totals.propTypes = {
  className: PropTypes.string,
  subTotal: PropTypes.string,
  taxAndTotal: PropTypes.string,
  isLoading: PropTypes.bool,
  deliveryTip: PropTypes.objectOf(PropTypes.number),
  deliveryTipIsDisplayed: PropTypes.bool,
  deliveryTipLoading: PropTypes.bool,
  promoFreeActive: PropTypes.bool,
  taxAmount: PropTypes.string,
};

Totals.defaultProps = {
  className: 'all-totals',
  subTotal: '0',
  taxAndTotal: '0',
  isLoading: false,
  deliveryTip: {},
  deliveryTipIsDisplayed: false,
  deliveryTipLoading: false,
  promoFreeActive: false,
  taxAmount: '',
};

export default Totals;
