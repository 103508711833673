import { createSelector } from 'reselect';

export const types = {
  DEVICE_ONLINE: '[Device] Online',
  DEVICE_OFFLINE: '[Device] Offline',
  HIDE_MESSAGE: '[Device] Hide message',
};

export const actions = {
  deviceOnline: () => ({ type: types.DEVICE_ONLINE }),
  deviceOffline: () => ({ type: types.DEVICE_OFFLINE }),
  hideMessage: () => ({ type: types.HIDE_MESSAGE }),
};

export const initialState = {
  isOnline: true,
  displayMessage: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DEVICE_ONLINE: {
      return { ...state, isOnline: true, displayMessage: true };
    }

    case types.DEVICE_OFFLINE: {
      return { ...state, isOnline: false, displayMessage: true };
    }

    case types.HIDE_MESSAGE: {
      return { ...state, displayMessage: false };
    }

    default:
      return state;
  }
};

export const selectDevice = state => state.device;
export const selectIsDeviceOnline = createSelector(
  selectDevice,
  device => device.isOnline,
);

export const selectDisplayMessage = createSelector(
  selectDevice,
  device => device.displayMessage,
);
