class ReduxStore {
  static savedStore = null;

  static get state() {
    return this.savedStore?.getState?.() || {};
  }

  static dispatch(action) {
    return this.savedStore?.dispatch?.(action) || {};
  }

  static set store(newStore) {
    this.savedStore = newStore;
  }
}

export default ReduxStore;
