import { theme as CowponentsTheme } from '@cfacorp/cowponents/dist/theme';

const theme = {
  ...CowponentsTheme,
  primary: '#004F71',
  secondary: '#ffffff',
  tertiary: '#DD0031',
  accent: '#004F71',
  background: '#f5f5f5',
  backgroundBlur: '#f5f5f5e8',
  text: '#5B6770',
  outline: '#aaaaaa',
  disabled: '#e5e0e0',
  success: '#249E6B',
  error: '#DD0031',
  warning: '#cca206',
  fontFamily: 'Apercu',
  smallTextFont: 'normal 12px Apercu, sans-serif',
  smallBoldFont: 'normal bold 13px Apercu, sans-serif',
  regularTextFont: 'normal 15px Apercu, sans-serif',
  regularBoldFont: 'normal bold 15px Apercu, sans-serif',
  small: '320px',
  phone: '600px',
  tablet: '768px',
  medium: '900px',
  desktop: '1024px',
  breakpoints: [600, 768, 900, 1024],
};

export default theme;

/*
  primary
    red: #DD0031
    white: #FFFFFF
  secondary:
    red: #AF272F
    navy: #004F71
    aqua: #3EB1C8
    gray: #5B6770
  tertiary
    orange: #FFB549
    pale yellow: #F5E1A4
    forest green: #00635B
    kelly green: #249E6B
    maroon: #5D3754
    plum: #994878
    orange sherbet: #Fa9370
    pink: #F8C1B8
    nude: #F5E3CC
 */
