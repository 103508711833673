/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

function OrderHistory({ orderHistory }) {
  return (
    <StyledOrderHistory>
      <h3 data-cy="order-history-label">Order History</h3>
      <div className="table-row header">
        <div className="cell" data-cy="timestamp-label">Timestamp</div>
        <div className="cell" data-cy="event-label">Event</div>
      </div>
      {orderHistory.map((history, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="table-row wrapper" key={`${history.sortableValue}${idx}`} data-cy={`${history.sortableValue}`}>
          <div className="data cell" data-cy={`timestamp-${idx}`}>{history.timestamp}</div>
          <div className="data cell" data-cy={`event-${idx}`}>{history.event}</div>
        </div>
      ))}
    </StyledOrderHistory>
  );
}

const StyledOrderHistory = styled.div`
  margin-top: 40px;
  font: ${(props) => props.theme.regularTextFont};
  & .table-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    min-height: 35px;
    padding-bottom: 0;
  }
  & .header {
    font-weight: bold;
    align-items: center;
    border-bottom: none;
    justify-content: space-around;
  }
  & .table-row:nth-child(odd) {
    background-color: ${(props) => props.theme.background};
  }
  & .cell {
    padding: 10px;
    width: 40%;
  }
  & .cell:nth-child(2) {
     width: 60%;
  }
`;

OrderHistory.propTypes = {
  orderHistory: PropTypes.arrayOf(PropTypes.object),
};

OrderHistory.defaultProps = {
  orderHistory: [],
};

export default OrderHistory;
