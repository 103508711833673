import PropTypes from 'prop-types';
import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components/macro';

function ModalHeader({
  left,
  right,
  title,
}) {
  return (
    <StyledModalHeader>
      <Box className="side" width={1 / 4}>{left}</Box>
      <Box className="center"><h2>{title}</h2></Box>
      <Box className="side" width={1 / 4}>{right}</Box>
    </StyledModalHeader>
  );
}

const StyledModalHeader = styled(Flex)` 
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.secondary};
  background-color: ${(props) => props.theme.accent};
  height: 75px;
  align-items: center;
  text-align: center;
  justify-content: space-between;
`;

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  left: PropTypes.element,
  right: PropTypes.element,
};

ModalHeader.defaultProps = {
  left: null,
  right: null,
};

export default ModalHeader;
