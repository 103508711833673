import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import logo from '../Icon/icons/CFA_Symbol.svg';

function Logo({ className }) {
  return (
    <div className={className}>
      <img src={logo} alt="Chick-fil-A Logo" />
      <div className="title">
        Catering
        <br />
        Management
        <br />
        Tool
      </div>
    </div>
  );
}

const StyledLogo = styled(Logo)`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  
  & .title {
    font: ${props => props.theme.smallTextFont};
    text-align: left;
    color: ${props => props.theme.text};
    margin: 10px;
    @media (max-width: ${props => props.theme.medium}) {
      display: none;
    }
  }
  
  @media (max-width: ${props => props.theme.tablet}) {
    margin: 0 10px;
  }
`;

Logo.propTypes = {
  className: PropTypes.string.isRequired,
};

export default StyledLogo;
